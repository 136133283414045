import { Container, Text } from '..';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { getColor } from '../../../utils';

interface TooltipProps {
  label: string;
  position?: string;
  type?: string;
}

const StyledTooltipContainer = styled(Container)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  &:hover {
    .tooltip-container {
      opacity: 1;
      visibility: visible;
    }
  }
  &.left {
    .tooltip-container {
      right: 100%;
      transform: translateX(-16px);
    }
    
  }
  &.bottom {
    .tooltip-container {
      bottom: calc(-100% - 8px);
    }
  }
  .tooltip-container {
    transition: .3s ease-in-out all;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    background: ${getColor('darkOverlay')};
    color: white;
    padding: 8px;
    position: absolute;
    p {
      color: ${getColor('white')};
      white-space: nowrap;
    }
  }
  &.sigma-container{
    z-index: 5;
    .tooltip-container{
      transition: none;
      top:20px;
      width: 99px;
      height:25px;
      border-radius: 2px;
      background-color: black;
      p{
        font-family: sans-serif;
        font-size: .7rem;
        font-weight: 400;
        color: white;
      }
    }
    .triangle{
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom-color:black;
      top: -10px;
      border-width: 0 5px 5px 5px;
      margin: 5px;
      position: absolute;
      width: 0px;
      height: 0px;
      border-style: solid;
    }
  }
`;

export const Tooltip = ({ label, position, type }: TooltipProps) => {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const getClassNames = (position:string|undefined,type:string|undefined)=>{
    let styles = position ? `${position} tooltip-trigger` : 'tooltip-trigger'
    styles = `${styles}${type ? ` ${type}`: ''}`
    return styles
  }
  return (
    <StyledTooltipContainer className={getClassNames(position,type)}>
      <Container className={'tooltip'}>
        <Text>
          {capitalizeFirstLetter(label)}
        </Text>
        <span className='triangle'></span>
      </Container>
    </StyledTooltipContainer>
  );
};
export default Tooltip;
