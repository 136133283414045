import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { getColor } from 'utils';
import { Container } from '../../shared'

const StyledNestedRoute = styled(Container)`
  flex-direction: column;
  max-height: 100%;
  .body-container {
    overflow: auto;
    width: 100%;
    background: ${getColor('brand')};
    flex: 1;
  }
`

export const NestedRoute = () => {
  return (
    <StyledNestedRoute expand className={"route"}>
      <Container className="body">
        <Outlet />
      </Container>
    </StyledNestedRoute>
  )
}

export default NestedRoute

