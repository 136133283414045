import { createReducer, SerializedError } from '@reduxjs/toolkit'
import actions from './actions'

export type AuthState = {
  user?: any
  isFetching: boolean
  token?: Record<string, string>
  error?: SerializedError
  message?: any
  dataTrackingSessionId?:any
}

const initialState: AuthState = {
  user: undefined,
  isFetching: false,
  token: {},
  error: undefined,
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getUser.pending, (state) => {
      state.isFetching = true
    })
    .addCase(actions.getUser.fulfilled, (state, action) => {
      state.message = action.payload?.message
      state.isFetching = false
      state.dataTrackingSessionId = action.payload?.dataTrackingSessionId
      state.user = action.payload?.user
      state.token = action.payload?.token
      state.message = action.payload?.message
      state.error = undefined
    })
    .addCase(actions.getUser.rejected, (state, action) => {
      state.isFetching = false
      state.user = undefined
      state.message = action.payload
      state.token = undefined
      state.error = action.error
    })
    .addCase(actions.updateUserWorkbookFilterList, (state, action) => {
      state.user = action.payload.user
    })
    
})

export default authReducer
