import styled from "styled-components";
import { Container } from "components"
const StyledDropdown = styled(Container)`
    .trigger-container{
        padding-right: 0px !important;
        width: 218px !important;
    }
    .dropdown-body-container{
        width: 250px !important;
    }
`

export default StyledDropdown