import { useEffect, useMemo, useState } from 'react'
import { Container } from '../../../shared'
import { hooks, mainOperations, mainSelectors } from '../../../../state'
import { IFilter, IFilterSelected, IFilterValue, IFiltersGroup, ISelectedFilters} from 'utils/constants/interfaces'
import { getSelectedValues } from 'utils/tools'
import { filtersLabels } from 'utils/constants'
import WorkbookFilter from './workbook-filter'

const WorkbookFilters = ()=>{
    const { useAppSelector, useAppDispatch } = hooks
    const dispatch = useAppDispatch()
    const { selectmain } = mainSelectors
    const { getWorkbookFilters, updateDefaultFilterSaved, saveWorkbookFilters } = mainOperations
    const { selectedWorkbookV2, workbookFilters, appType } = useAppSelector(selectmain)
    const [ filters, setFilters ] = useState<IFilter[]>(structuredClone(workbookFilters)!)
    const [ clearCheckboxes, setClearCheckBoxes] = useState(false)

    const hasFiltersValuesSelected = useMemo(() => {
        return workbookFilters && workbookFilters?.filter((f:IFilter)=>{
            if(f.filterName === filtersLabels[0] || f.filterName === filtersLabels[1]){
                const containsValuesSelected = f.filterValues.filter((fv: IFilterValue)=>fv.isSelected).length > 0
                return containsValuesSelected
            }
            return false
        }).length > 0
    },[workbookFilters])

    const areSpecificFilters = useMemo(()=>{
        const filteredFilters = workbookFilters?.filter((f: IFilter)=> filtersLabels.includes(f.filterName))
        if(filteredFilters && filteredFilters.length > 0){
            return filteredFilters
        }
        return []
    },[workbookFilters])

    const checkedFilters = useMemo(()=>{
        const checkedFilters = getSelectedValues(workbookFilters!)
        setFilters(workbookFilters!)
        return checkedFilters
    },[workbookFilters])

    useEffect(()=>{
        if(filters && clearCheckboxes){
            (async()=>{
                const formattedFilters = [] as IFilterSelected[]
                filters.forEach(({filterName,filterSettingGuid,filterValues, order }:IFilter)=>{
                    const selectedFilterValues = filterValues.filter(({isSelected}:IFilterValue)=>isSelected).map(({id,value}:IFilterValue)=>({id,value}))
                    formattedFilters.push({filterName, filterSettingGuid, filterValuesSelected: selectedFilterValues, order})
                })

                const filtersGroup: IFiltersGroup = {
                    workbookGuid: selectedWorkbookV2?.workbookGuid,
                    filters: checkedFilters,
                    isDefault: false,
                    isAutoSaving: true,
                    savedFilterName: '',
                    sigmaControls: selectedWorkbookV2?.sigmaControls || []
                }
                await dispatch(getWorkbookFilters({workbookGuid: selectedWorkbookV2?.workbookGuid, filters: formattedFilters}))
                await dispatch(saveWorkbookFilters(filtersGroup))
                await dispatch(updateDefaultFilterSaved(undefined))
                setClearCheckBoxes(false)
            })()
        }

    },[filters])

    const applyFilterChanges = async (selectedFilters:IFilterValue[], filterSettingGuid: string, isMultipleSelection: boolean, sortBy: string ) => {
        const filterSelected = workbookFilters?.find((filter: IFilter)=> filter.filterSettingGuid === filterSettingGuid)
        const newPrev = checkedFilters?.filter((f:ISelectedFilters)=>f.filterSettingGuid !== filterSelected?.filterSettingGuid)
        const { workbookGuid } = selectedWorkbookV2
        if(isMultipleSelection){
            const valuesSelected = selectedFilters?.map(({isSelected,...rest}:IFilterValue)=>{return {...rest}})!
            await dispatch(getWorkbookFilters({workbookGuid, filters: [{ filterSettingGuid: filterSettingGuid,order: sortBy, filterName: filterSelected?.filterName, filterValuesSelected: [...valuesSelected] },...newPrev]  }))
            await dispatch(updateDefaultFilterSaved(undefined))
            
        }else{
            if(selectedFilters.length === 1){
                await dispatch(getWorkbookFilters({workbookGuid, filters: [{ filterSettingGuid: filterSettingGuid, order: sortBy, filterName: filterSelected?.filterName, filterValuesSelected: selectedFilters },...newPrev]  })) 
            }
        }        
    }

    const orderFiltersByBatch = (filters: IFilter[])=>{
        let filtersLength = filters.length - 1
        let lastBatch = filters[filtersLength]?.filterBatch
        let batchs: IFilter[][][] = [] as IFilter[][][]
        for(let i = 0; i <= lastBatch; i++){
            let batchByGroup: IFilter[][]= [] as IFilter[][]
            let matchedFilters = filters.filter((filter: IFilter) => filter.filterBatch === i)
            let matchedFiltersLength = matchedFilters.length - 1
            if(matchedFilters){
                let lastGroupFilterPosition = Number(matchedFilters[matchedFiltersLength]?.groupFilterPosition)
                for(let j = 0; j <= lastGroupFilterPosition; j++){
                    let matchedGroupFilterPosition = matchedFilters.filter((filter:IFilter)=>Number(filter.groupFilterPosition) === j)
                    if(matchedGroupFilterPosition){
                        batchByGroup.push(matchedGroupFilterPosition)
                    }
                }
                batchs.push(batchByGroup)
            }    
        }
        return batchs
    }

    const restoreSelectedValues = (filterSettingGuid:string, ) =>{
        const newWorkbookFilters = workbookFilters?.map(({filterValues,...rest}:IFilter)=>{
            if(filterSettingGuid === rest.filterSettingGuid){
                let updatedFilterValues = filterValues.map(({isSelected,...rest}:IFilterValue)=>{
                    if(isSelected){
                        return({isSelected: !isSelected,...rest})
                    }
                    return ({isSelected,...rest})
                })
                return({...rest,filterValues: updatedFilterValues})
            }
            return({...rest,filterValues})
        })!
        setFilters(newWorkbookFilters)
        setClearCheckBoxes(true)
    }

    return (
      <Container className={'filters'}>
      {
        filters!?.length > 0 && orderFiltersByBatch(filters!).map((batch:IFilter[][],batchIndex: number, batchs)=>{
            let batchsLength = batchs.length
            return (<Container className={`batch-container ${batchsLength >= 2 ? 'border-right': 'batch-100'}`} key={`${batchIndex}-${batch[batchIndex]}`}>
                {
                    batch.map((batchGroup:IFilter[],batchGroupIndex)=>{
                        const currentBatchGroup = batchGroup[batchGroupIndex]
                        const { filterBatch, filterSettingGuid } = currentBatchGroup
                        const batchGroupKey = `${filterBatch}-${filterSettingGuid}`
                        return <Container className={'batch-group'} key={batchGroupKey}>
                            {
                                batchGroup.map((filterByGroup:IFilter,filterIndex)=>{
                                    let orderedFilters = orderFiltersByBatch(workbookFilters!)
                                    const isFilterDisabled = hasFiltersValuesSelected! && areSpecificFilters?.length === 4 && filtersLabels.slice(2,4).includes(filterByGroup.filterName)
                                    if(!orderedFilters[batchIndex] || !orderedFilters[batchIndex][batchGroupIndex] || !orderedFilters[batchIndex][batchGroupIndex][filterIndex]){
                                        return (<></>)
                                    }

                                    return(
                                        <Container className={`dropdown-menu-container ${batchGroup.length > 4 ? 'sm': ''}`} key={filterByGroup.filterSettingGuid}>
                                            <WorkbookFilter
                                                hasInfoIcon={filterByGroup.filterName === 'STATE' && appType === 'insights'}
                                                disabled={isFilterDisabled}
                                                filter={filterByGroup}
                                                onChange={(values:any[],sortBy:string)=>applyFilterChanges(values,filterByGroup.filterSettingGuid, filterByGroup.allowMultipleSelection,sortBy)}
                                                restoreSelectedValues={()=>restoreSelectedValues(filterByGroup.filterSettingGuid)}
                                            />
                                        </Container>
                                    ) 
                                })
                            }
                        </Container>
                    })
                }
            </Container>)
        })
      }
      </Container>
    )

}

export default WorkbookFilters