import React, { ChangeEventHandler, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from '.'
import { Button, Container } from '..'
import { Caret } from './assets'

interface PaginatorProps {
  totalRecords: number,
  onPaginationChange: (newPage: number, rowsPerPage: number ) => void
  qtyPerPage: number
  page: number
}

const StyledPaginator = styled(Container)`
  justify-content: flex-end;
  padding: 0 32px;
  overflow: hidden;
  gap: 32px;
  .rows-selector-container {
    align-items: center;
    gap: 4px;
    select {
      border: none;
      outline: none;
      background: none;
    }
  }
  .pagination-buttons-container {
    button {
      border-radius: 0;
      background: none;
      padding: 0;
      &:nth-of-type(1) {
        transform: rotate(180deg);
      }
    }
  }

`

export const Paginator = ({ onPaginationChange, totalRecords, qtyPerPage, page }: PaginatorProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [rowNumber, setRowNumber] = useState(10)

  const rangeFrom = page === 1 ? 1 : (page - 1) * qtyPerPage
  const rangeTo = (page * qtyPerPage) < totalRecords ? (page * qtyPerPage) : totalRecords
  const recordRange = `${rangeFrom} - ${rangeTo}`

  const onChange = (e:any) => {
    setCurrentPage(1)
    setRowNumber(e.target.value)
  }

  const changePage = (newPage:number) => {
    const isPositive = !!newPage
    const isDecreasing = (currentPage - newPage) > 0
    const doesNotEceedLimit = isDecreasing || ( rangeTo < totalRecords )

    if (isPositive && doesNotEceedLimit) {
      setCurrentPage(newPage)
    }
  }
  
  useEffect(() => {
    onPaginationChange(currentPage, rowNumber)
  }, [currentPage, rowNumber])
  

  return (
    <StyledPaginator expand className="paginator">
      <Container className="rows-selector">
        <Text textStyle='s1'>Rows per page:</Text>
        <select onChange={onChange}> 
          <option>10</option>
          <option>50</option>
          <option>100</option>
        </select>
      </Container>
      <Container className="current-page-indicator">
        <Text textStyle='s1'>{`${recordRange} of ${totalRecords}`}</Text>
      </Container>
      <Container className="pagination-buttons">
        <Button onClick={() => changePage(currentPage - 1)}><Caret /></Button>
        <Button onClick={() => changePage(currentPage + 1)}><Caret /></Button>
      </Container>

    </StyledPaginator>
  )
}

export default Paginator