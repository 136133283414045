import * as React from "react"
import { SVGProps } from "react"
import { getColor } from "../../utils"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 .5H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-12l-4-4Zm2 16H2v-14h11.17L16 5.33V16.5Zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3Zm-6-6h9v4H3v-4Z"
      fill={getColor('brandDark')()}
    />
  </svg>
)

export default SvgComponent
