import SortIcon from './sort-icon'

interface ISortOptionProps {
    type: string
    onClickHandler: Function
}
export const SortOptionDropdown = ({type, onClickHandler}:ISortOptionProps) => {
    return(<>
        {
            type === 'ASC' ? <SortIcon isArrowVisible={true} isAscending isAlphaNumeric width='16' onClick={()=>onClickHandler()}/> :
            <SortIcon isArrowVisible={true} isAlphaNumeric width='16' onClick={()=>onClickHandler()}/>  
        }
    </>)
}

export default SortOptionDropdown