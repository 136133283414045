import { Container, Text } from '..';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { getColor } from '../../../utils';

interface TooltipProps {
  label: string;
  children: ReactNode;
}

const StyledTooltipContainer = styled(Container)`
  position: relative;
  display: inline-block;

  .tooltip-text-container {
    visibility: hidden;
    opacity: 0;
    transition: ease-in-out .3s all;
    background-color: ${getColor('text')};
    padding: 4px 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    max-width: 250px;
    width: 150px;
    margin-left: -45px;
  }

  &:hover .tooltip-text-container {
    visibility: visible;
    opacity: 1;
  }
`;

export const LegacyToolTip = ({ label, children }: TooltipProps) => {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <StyledTooltipContainer className='tooltip'>
      {children}
      <Container className='tooltip-text'>
        <Text textStyle='support' color='white' textAlign='center'>
          {capitalizeFirstLetter(label)}
        </Text>
      </Container>
    </StyledTooltipContainer>
  );
};
export default LegacyToolTip;
