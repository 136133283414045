import { Button, Container, Text } from "components/shared"
import { BenefitIcon , WhiteImage} from "../../../../assets"
import WorkbookPreviewStyled from "./workbook-preview-styled"
import { upgradeNow } from "utils/constants"
import BenefitItem from "./benefit-item"

interface IWorkbookPreviewProps{
    workbookGuid: string
    workbookName: string
    workbookMessageBenefits: string[] | undefined
    icon: any
    workbookImage?: {Desktop:any, Laptop: any, TwoXL: any, ThreeXL: any} | undefined
    workbookDisplayName: string | undefined
    workbookMessageHeaders: string[] | undefined
    workbookMessageFooters: string[] | undefined
}

export const WorkbookPreview = ({workbookImage , icon,...props}:IWorkbookPreviewProps) => {
    const { workbookMessageBenefits, workbookMessageFooters, workbookMessageHeaders } = props 
    return (<WorkbookPreviewStyled className="workbook-preview">
        {
            workbookImage ? 
            <div className="workbook-preview-image">
                <img className="workbook-image"
                    src={workbookImage.Laptop}
                    srcSet={`${workbookImage.Laptop} 960w, ${workbookImage.Desktop} 1280w, ${workbookImage.TwoXL} 1536w, ${workbookImage.ThreeXL} 1920w`}
                />
            </div>
            :
            <Container className="workbook-loading">
                <Text>Loading</Text>
            </Container>
        }
        <div className="benefits">
            <div className="fade-out" style={{display: `${workbookImage ? 'block' : 'none'}`}}>
            </div>
            <div className="workbook-preview-content" style={{display: `${workbookImage ? 'flex': 'none'}`}}>
                <div className="workbook-preview-body">
                    <Container className={"preview-headers"}>
                        {
                            workbookMessageHeaders?.length && workbookMessageHeaders.map((text: string)=>{
                                return(<Text textStyle="h2" key={text}>{text}</Text>)
                            })
                        }
                    </Container>
                    <Container className={'benefits-list'}>
                        {
                            workbookMessageBenefits?.length && workbookMessageBenefits.map((benefit:string)=>{
                                return(<BenefitItem className="benefit-item" icon={icon} text={benefit} key={benefit}/>)
                            })
                        }
                    </Container>
                    <Container className={'message-footers'}>
                        {
                             workbookMessageFooters?.length && workbookMessageFooters.map((text)=>(
                                <Text textStyle="h5" key={text}>{text}</Text>
                            ))
                        }
                    </Container>
                </div>
            </div>
        </div>
    </WorkbookPreviewStyled>)
}

export default WorkbookPreview