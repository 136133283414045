import { Button, Container, Spinner, Text } from "components/shared"
import StyledPremiumDownload from "./premium-download-styled"
import DateRangePicker from "../../../shared/inputs/DateRangePicker/date-range-picker"
import Dropdown from "./dropdown"
import RangeOptions from "../../../shared/inputs/DateRangePicker/range-options"
import { SetStateAction, useEffect, useMemo, useState } from "react"
import { hooks , mainOperations, mainSelectors} from "state"
import { IDownloadPremiumExport, IExportPremium, IFilter } from "utils/constants/interfaces"
import { LoadingSpinner } from "assets"
import { sliceLabel } from "utils"
import { selectedDateRangeValue  } from 'utils/constants/interfaces'
import { fileTypes, defaultSource } from 'utils/constants'


const PremiumDownload = ()=>{
    const [isFileTypesOpen, setIsFileTypesOpen] = useState(true)
    const [ values , setValues ] = useState({} as IFilter)
    const [selectedDateRangeValue, setSigmaControlValue] = useState({} as selectedDateRangeValue)
    const [fileTypeSelected, setFileTypeSelected] = useState(fileTypes[1].label)
    const [sourceSelected, setSourceSelected] = useState(defaultSource)
    const { selectmain } = mainSelectors
    const { useAppSelector, useAppDispatch } = hooks
    const { exportPremiumFilters, exportPremiumList, isFetchingExportPremiumList} = useAppSelector(selectmain)

    const { getWorkBookPremiumFilters, downloadPremiumExport } = mainOperations
    const dispatch = useAppDispatch()

    const handleSourceSelection = (value: SetStateAction<string>) => {
        if(exportPremiumList && exportPremiumList?.length > 0){
            const premiumExport = exportPremiumList.find((e: IExportPremium)=>e.elementDisplayName === value)!
            if(premiumExport.workbookElementGuid){
                setSourceSelected({...premiumExport})
            }
        }
    }
    const handleFileTypeSelection = (file: SetStateAction<string>) =>{
        setFileTypeSelected(file)
    }

    const handleOnClick = ()=>{
        if(selectedDateRangeValue.sigmaFormat.length > 0 && fileTypeSelected && values.filterValues.find(fc=>fc.isSelected) && exportPremiumList){
            const {filterSettingGuid, filterName, order, filterValues } = values
            const filterValuesSelected = filterValues.filter((fv)=>fv.isSelected)
            const payload: IDownloadPremiumExport = {
                workbookGuid: sourceSelected.workboookGuid,
                filters:[
                    {
                        filterSettingGuid,
                        filterName,
                        filterValuesSelected: [...filterValuesSelected.map((fvs)=>({id: sliceLabel(fvs.id), value: sliceLabel(fvs.value)}))],
                        order
                    }
                ],
                fileFormat: fileTypeSelected,
                workbookElementGuid: sourceSelected.workbookElementGuid,
                sigmaControls: [
                    {
                        sigmaControlId: "DATE_RANGE",
                        values: selectedDateRangeValue.sigmaFormat
                    }
                ]
            }
            const stateIdSelected = filterValuesSelected[0].id.slice(0,filterValuesSelected[0].id.length - 1)
            const fileName = `${sourceSelected.workbookDisplayName} - ${stateIdSelected} - ${selectedDateRangeValue.dateRangeSelected}`
            dispatch(downloadPremiumExport({ export: payload, fileName: fileName, format: fileTypeSelected}))
        }
    }
    useEffect(()=>{
        if(exportPremiumFilters && exportPremiumFilters?.length > 0){
            setValues({...exportPremiumFilters[0], filterValues: exportPremiumFilters[0].filterValues.map((fv,i)=>({...fv,value: fv.value + 0,  id: fv.id +  0})) })
        }
    },[exportPremiumFilters])

    useEffect(()=>{
        if(exportPremiumList){
            dispatch(getWorkBookPremiumFilters({workbookGuid: exportPremiumList[0].workboookGuid}))
            const firstExportElement = exportPremiumList[0]
            if(firstExportElement){
                setSourceSelected(firstExportElement)
            }else{
                setSourceSelected(defaultSource)
            }
        }
    },[exportPremiumList,dispatch,getWorkBookPremiumFilters])

    const sourceOptions = useMemo(()=>{
        return exportPremiumList?.map(({ elementDisplayName }: IExportPremium)=>{ return({label: elementDisplayName})}) || []
    },[exportPremiumList])

    if(isFetchingExportPremiumList){
        return(<Container className={'loading-export-premium'}>
            <Spinner text='Loading' size='24px' color='brand'><LoadingSpinner/></Spinner>
        </Container>)
    }

    return(values.filterValues?.length > 0 || isFetchingExportPremiumList ? <StyledPremiumDownload className={'premium-download'}>
        <Container className={'download-filters'}>
            <Container className={'download-filter'}>
                <Text textStyle="h4">Export Options</Text>
                {
                    exportPremiumList && <RangeOptions isContentExpanded={true} selectedOption={sourceSelected.elementDisplayName} setSelectedOption={(v)=>handleSourceSelection(v)} options={sourceOptions}></RangeOptions>
                }
            </Container>
        </Container>
        <Container className={'download-filters'}>
                <Container className={'download-filter'}>
                    <Dropdown values={values}  setValues={(va)=>setValues(va)} />
                </Container>
                <Container className={'download-filter'}>
                    <DateRangePicker title="Date range (data available last 90 days)" setSigmaControlValue={(val)=>setSigmaControlValue(val)}/>
                </Container>
        </Container>
        <Container className={'download-filters'}>
            <Container className={'download-filter'}>
                    <Text textStyle="h4">File type</Text>
                    <RangeOptions options={fileTypes} isContentExpanded={isFileTypesOpen} selectedOption={fileTypeSelected} setSelectedOption={(v)=>handleFileTypeSelection(v)} />
            </Container>
        </Container>
        <Container className={'download-actions'}>
            <Container className={'download-filter'}>
                    <Button disabled={ !(selectedDateRangeValue?.sigmaFormat?.length > 0)} color="brandDark" className={'download-button'} onClick={handleOnClick}>Download</Button>
            </Container>
        </Container>
    </StyledPremiumDownload> : <Spinner text='Loading' size='24px' color='brand'><LoadingSpinner/></Spinner>)
}

export default PremiumDownload