import { Container } from 'components'
import styled from 'styled-components'
import { getColor } from 'utils'
import { devices } from 'utils/constants/media-queries'

const StyledDashboard = styled(Container)`
  flex-direction: column;
  overflow: hidden;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  height: 100vh;
  background-color: white;
  .workbook-actions-container {
    z-index: 3;
  }
  .workbooks-section-container {
    flex-direction: column;
    width: 100%;
    flex: 1;
    align-items: flex-start;
    gap: 0;
    justify-content: flex-start;
    position: absolute;
    top:0;
    height: 100vh;
    background-color: #FBFAFF;
    .spinner-wrapper-container{
      flex-direction: row;
      align-items: center;
      height: 250px;
      p{
       font-size: 1.2rem; 
      }
      .spinner-content-container{
        margin-bottom: 0;
        margin-right: 15px;
        .loading-spinner{
          --spinner-b-color: black;
        }
      }
    } 
    .title-container{
      padding: 20px 0px 20px 40px;
    }
    .workbooks-section-viewer-container{
      width: 100%;
      height: 100%;
      transition: width 650ms ease-in-out;
      &.expanded-container{
        width: 95%;
      }
      &.colapsed-container{
        width: 100%;
      }
    }
    .workbooks-section-filters-container{
      display: none;
      max-width: 340px;
      background-color: white;
      justify-content: start;
      height: 100vh;
      position: relative;
      top:0;
      left: 0;
      z-index: 3;
      transition: transform 650ms ease-in-out;
      &.expanded-container{
      }
      &.colapsed-container{
        transform: translateX(-92%);
        position: absolute;
        .sidebar-filters-wrapper-container{
          transition: all 10s;
          >div{
            display: none;
          }
        }
      } 
      .arrow-container{
        display: none;
        position: absolute;
        right: -20px;
        top: 20px;
        padding: 10px;
        border: 1px solid ${getColor('brand')};
        border-radius: 100%;
        background-color: white;
      }
    }
  }
  .sidebar-section-container{
    height: 100%;
    width: 80%;
    background-color: white;
    border-right: 1px solid ${getColor('brand')};
    justify-content: start;
    padding: 32px;
    height: 100vh;
    position: absolute;
    top:0;
    left: 0;
    z-index: 10;
    transition: transform 650ms ease-in-out;
    &.expanded-container{
    }
    &.colapsed-container{
      transform: translateX(-92%);
    } 
    .arrow-container{
      position: absolute;
      right: -20px;
      top: 20px;
      padding: 10px;
      border: 1px solid ${getColor('brand')};
      border-radius: 100%;
      background-color: white;
    }
  }
  .sidebar-overlay-container{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    width: 100%;
    height: 100%;
    &.disabled-container{
      background-color: rgba(0, 0, 0, 0);
      display: none;
    }
  }
  
  @media only screen and ${devices.tablet}{
    .sidebar-section-container{
      display: none;
    }
    .sidebar-overlay-container{
      display: none;
    }
  }
  @media only screen and ${devices.desktop}{
    .workbooks-section-container{
      display: flex;
      .workbooks-section-filters-container{
        display: flex;
        .arrow-container{
          display: block;
        }
      }
    }
  }
`

export default StyledDashboard