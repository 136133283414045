import React from 'react'
import styled from 'styled-components'

import { Container, Text } from '..'
import { getColor } from '../../../utils'

interface SwitchInputProps {
  label?: string
  switchStyle?: 'default' | 'secondary'
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: boolean
  readOnly?: boolean
  name: string
}

const StyledSwitchInput = styled(Container)`
  .switch-wrapper-container {
    position: relative;
  }

  .switch-label {
    position: absolute;
    top: 1;
    left: 0;
    border-radius: 15px;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      transition: 0.2s;
    }
  }

  .switch-input {
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 48px;
    height: 24px;
    cursor: pointer;

    &:checked + .switch-label {
      &::after {
        content: '';
        display: block;
        border-radius: 50%;
        transition: 0.2s;
      }
    }
  }

  &.default-container .switch-label {
    background: ${getColor('brand')};
    width: 48px;
    height: 24px;
    &::after {
      width: 18px;
      height: 18px;
      margin: 3px;
      background: ${getColor('white')};
    }
  }

  &.default-container .switch-input {
    &:checked + .switch-label {
      background: ${getColor('brand')};
      &::after {
        width: 18px;
        height: 18px;
        margin-left: 26px;
      }
    }
  }

  &.secondary-container .switch-label {
    background: ${getColor('brandDark')};
    width: 34px;
    height: 14px;
    &::after {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
      width: 20px;
      height: 20px;
      margin: -3px 0 0 -3px;
      background: ${getColor('inactive')};
    }
  }

  &.secondary-container .switch-input {
    width: 34px;
    height: 14px;
    &:checked + .switch-label {
      background: ${getColor('brand')};
      &::after {
        width: 20px;
        height: 20px;
        margin-left: 17px;
        background: ${getColor('brand')};
      }
    }
  }
`

export const SwitchInput = ({
  label,
  switchStyle = 'default',
  value,
  readOnly,
  onChange,
  name
}: SwitchInputProps) => {
  return (
    <StyledSwitchInput className={switchStyle}>
      {label && <Text>{label}</Text>}
      <Container className="switch-wrapper">
        <input
          id={name}
          type="checkbox"
          className="switch-input"
          checked={value}
          onChange={onChange}
          disabled={readOnly}
        />
        <label htmlFor={name} className="switch-label" />
      </Container>
    </StyledSwitchInput>
)
}

export default SwitchInput
