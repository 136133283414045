import { useEffect, useState, useMemo } from 'react'
import { AiFillCaretDown } from 'react-icons/ai'
import styled from 'styled-components'
import { Button } from '..'
import { Container, Dropdown, Text } from '../..'
import StyledDropdownInput from './dropdown-input-styled'
import Checkbox from '../../ui-elements/checkbox'
import { IoClose } from 'react-icons/io5'
import CalendarIcon from './calendar-icon'
import { getColor } from '../../../../utils'
import RangeDropdownInput from '../RangeDropdownInput'
import { hooks, mainSelectors } from 'state'
import {dropdownProps } from './constants'
const Arrow = styled(Container)`

`
export const DropdownInput = (props=dropdownProps) => {
  let { onChange: onChangeProps } = props
  const initialValues = useMemo(()=>{
        return props.label?.split(',').reduce((acc:any, curr:string) =>{return({ ...acc, [curr]: true })}, {})
  },[props.label])
  const [ isExpanded, setIsExpanded ] = useState(false)
  const [ values, setValues ] = useState(initialValues)
  
  const [ message, setMessage ] = useState('')

  const onClose = () => {
    setIsExpanded(false)
    setValues(initialValues)
  }

  const onChange = (item:any) => {
    if(item.label){
      onChangeProps(item)
      onClose()
      return
    }
    onClose()
    
  }



  const onUpdateStates = (valuesN:any) => {
    let newValues = ''
    Object.keys(valuesN).forEach((value: string) => { if (valuesN[value]) newValues = `${newValues ? `${newValues},` : ''}${value}` })
    onChangeProps(newValues)
    onClose()
  }

  const onUpdateCategories = () => {
    let newValues = ''
    Object.keys(values).forEach((value: string) => { if (values[value]) newValues = `${newValues ? `${newValues},` : ''}${value}` })
    onChangeProps(newValues)
    onClose()
  }

  const getChunkSelected = () =>{
    return Object.entries(values).filter((arr:any)=>{return arr[0] !== '' && arr[1] === true}).length
  }
  const renderTrigger = () => {
    if (props.trigger) {
      return (
        <Container className={'trigger'} onClick={() => ( !props.onHover && isExpanded) ? onChange(props.selectedOption) : setIsExpanded(true)}>
          {props.trigger}
        </Container>
      )
    }
    return (
      <Container expand className={`trigger-container${props.isRangeDropdownInput ? ' large' : ' '}`} onClick={() =>(!props.onHover && isExpanded) ? onChange(props.selectedOption) : setIsExpanded(true)}> 
        {
          props.isRangeDropdownInput && (
            <Container className={'label'} >
              <div style={{marginRight:'10px'}}>
                <CalendarIcon styles={{marginRight: '10px'}} color={getColor('brand')()} width='20'/>
              </div>
            </Container>
          )
        }
        {
          (
            <Container className={'label'}>
              {
                typeof props.label === 'boolean' ? <Text>{props.options[0].label}</Text>
                : <Text color={props.label ? 'text':'gray1'}>{`${props.label?.split(',').length! > 1 ? `(${props?.label?.split(',').length}) ` : ''}${props.label !== '' ? props.label?.replaceAll(',',', ') : 'Select values'}`}</Text>
              }
            </Container>
          )
        }
        { props.selectedOption?.label && <Text>{props.selectedOption.label}</Text> }
        { props.selectedOption?.icon }
        {
          props.isRangeDropdownInput && ( 
            <button  style={{border: 'none', cursor:'pointer'}} className={`x-icon ${true ? 'visible' : 'hidden'}`} onClick={()=>{}}>
                        <IoClose/>
            </button>
          )
        }
        <Container>
          { props.allowMultipleSelection && props.label && !props.isValueRequired && (
                  <Button className={'clear'} tooltip={{label: 'Clear selection', position:'bottom', type:'sigma'}} onClick={(e)=>{e.stopPropagation();props?.restoreSelectedValues!();}}>
                    <svg fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="css-9vk7ga"><title></title><path fill-rule="evenodd" clip-rule="evenodd" d="M11.293 12L7.646 8.354l.708-.708L12 11.293l3.646-3.647.708.708L12.707 12l3.647 3.646-.707.708L12 12.707l-3.646 3.647-.708-.707L11.293 12z" fill="currentColor"></path></svg>
                  </Button>
          )}
          { !props.hideCaret && <Arrow className={`arrow`} ><AiFillCaretDown size={10} color='#736a8c'/></Arrow> }
        </Container>
      </Container>
    )
  }
  useEffect(()=>{
    setValues(props.label?.split(',').reduce((acc:any, curr:string) =>{return({ ...acc, [curr]: true })}, {}))
  },[props.label])
  useEffect(() => {
    let hasValues = false
    Object.keys(values || {}).forEach((val: any) => {
      if (values[val]) {
        hasValues = true
      }
    })

    if (hasValues) {
      setMessage('')
    } else {
      setMessage(props.noSelectionMessage!)

    }
  }, [values])

  if(props.isRangeDropdownInput){
    return(
      <StyledDropdownInput className={`default ${isExpanded ? 'active ' : '' } ${props.disabled ? 'disabled ': ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}>
          <RangeDropdownInput staticRanges={props.staticRanges}/>
        </Dropdown>
      </StyledDropdownInput>
    )
  }
  if(props.allowSingleSelection){
    return (
      <StyledDropdownInput className={`default ${isExpanded ? 'active ' : '' } ${props.disabled ? 'disabled ': ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}>
          <Container className={'options'}>
            {
              props.options.map((option:Record<string, any>) => {
                const onCheckOption = (e:any) => {
                  setValues({...values,[option.key]:e.target.checked})
                }
                const isChecked = props.label === option.label ? true : false
                return (<Container key={option.label} className={`role-container${isChecked ? ' checked' : ' not-checked'}`} >
                  <Checkbox type='radio' checked={isChecked} onChange={(e)=>{onCheckOption(e); onChangeProps(option.key,props.filterSettingGuid)}} isRoundedCheckbox={true} label={option.label} name={props.filterSettingGuid}/>
                </Container>)
              })
            }
          </Container>
        </Dropdown>
      </StyledDropdownInput>
    )
  }
  if (props.allowMultipleSelection) {
    return (
      <StyledDropdownInput className={`default ${isExpanded ? 'active ' : '' } ${props.disabled ? 'disabled ': ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}>
          <Container className={'options'}>
            {
            //TODO: Implement Checkbox  "All" for selecting all the filter values
            /* <Container className={`role-container${true ? ' checked' : 'not-checked'}`}>
              <Checkbox type='checkbox' checked={label.split(',').length === options.length} onChange={()=>console.log('Checked all')} isRoundedCheckbox={false} label='All'/>
            </Container> */}
            {
              props.options.length !== 0 ? props.options.map((option:Record<string, any>) => {
                const onCheckOption = (e:any) => {
                  setValues({ ...values, [option.key]: e.target.checked })
                }
                const isChecked = values[option.key] ? true : false
                return (<Container key={option.key} className={`role-container${isChecked ? ' checked' : ' not-checked'}`} >
                  <Checkbox type="checkbox" checked={isChecked} onChange={(e)=>onCheckOption(e)} label={`${option.label}`} isRoundedCheckbox={false} parentId={props.filterSettingGuid}/>
                </Container>)
              }) : <div style={{margin: '8px'}}><Text textStyle='s1' textAlign='left'>0 results found</Text></div>
            }
            {
              message 
              ? (
                <Container className={'message'}>
                  <Text>{message}</Text>
                </Container>
              )
              : (
                props.options.length !== 0 &&
                <Button className={'action-button'} disabled={!Object.values(values).includes(true) && props.isValueRequired} onClick={onUpdateCategories}>
                  <Text>{'Apply'}</Text>
                </Button> 
              )
            }
          </Container>
        </Dropdown>
      </StyledDropdownInput>
    )
  }
  return (
    <div
    className={`dropdown-input-wrapper`}
    style={{
      display: 'flex',
      width: props.width || '100%',
      height: '100%',
    }}
      onMouseLeave={() => {
        if (props.onHover) {
          props.onHover()
          return setIsExpanded(false)
        }
      }}
      onMouseEnter={() =>  {
        if (props.onHover) {
          props.onHover()
          return setIsExpanded(true)
        }
      }}
    >
      <StyledDropdownInput className={`${props.clearStyles ? '': 'default '}${isExpanded ? 'active ' : ''}${props.disabled ? 'disabled ': ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}> 
          <Container className={'options'}>
            {props.options.map((option: Record<string, any>) =>  
              <Container key={option.key} className={`role`} onClick={() => onChange(option)}>
                <Text>{option.label}</Text> 
              </Container>
            )}
          </Container>
        </Dropdown>
      </StyledDropdownInput>
    </div>
  )
}

export default DropdownInput
