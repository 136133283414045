import React, { ReactNode } from 'react'
import styled from 'styled-components';
import { Container } from '.';

interface CardProps {
  children: ReactNode
}

const StyledCard = styled(Container)`
  .child-contariner {
  }
`

export const Card = ({children}: CardProps) => {
  return (
    <StyledCard className="card">
      <Container className="child">
        sadas
      </Container>
    </StyledCard>
  )
}

export default Card;
