import { IWorkbookElement } from "utils/constants/interfaces"
import { Button, Container } from "components/shared"
import { Text } from "components/shared/ui-elements"
import { BsFileEarmarkSpreadsheet } from "react-icons/bs"
import { LoadingSpinner } from "../../../../assets"


interface WorkbookElementsListProps{
    workbookElements: IWorkbookElement[],
    download: (workbookElementGuid:any, format:any, fileName:any, elementDisplayName: string | undefined)=>void
}
const WorkBookElementsList = ({workbookElements,download}:WorkbookElementsListProps) =>{
    return <>
        {
            workbookElements?.length > 1 && <Container className={'workbook-element-row'}>
            <Container className={'workbook-element-title'}>
              <Text>{'Download All'}</Text>
            </Container>
            <Container className={'workbook-element-download-options'}>
              <Button
                onClick={() => download('all', 'csv', workbookElements, undefined)}
              >
                <Text>CSV</Text>
                <BsFileEarmarkSpreadsheet size={20} />
              </Button>
              <Button
                onClick={() => download('all', 'xlsx', workbookElements, undefined)}
              >
                <Text>XLSX</Text>
                <BsFileEarmarkSpreadsheet size={20} />
              </Button>
            </Container>
          </Container>
        }
        {
            workbookElements?.map(({workbookDisplayName, workbookElementGuid, elementDisplayName}:IWorkbookElement)=>{
                return(
                <Container className={'workbook-element-row'} key={workbookElementGuid}>
                    <Container className={'workbook-element-title'}>
                        <Text>{elementDisplayName}</Text>
                    </Container>
                    <Container className={'workbook-element-download-options'}>
                        <Button
                        onClick={() => download(workbookElementGuid, 'csv', workbookDisplayName, elementDisplayName)}
                        >
                        <Text>CSV</Text>
                        <BsFileEarmarkSpreadsheet size={20} />
                        </Button>
                        <Button
                        onClick={() => download(workbookElementGuid, 'xlsx', workbookDisplayName, elementDisplayName)}
                        >
                        <Text>XLSX</Text>
                        <BsFileEarmarkSpreadsheet size={20} />
                        </Button>
                    </Container>
                </Container>
                )
            })
        }
    </>
}

export default WorkBookElementsList