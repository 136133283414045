import { cookifyObject, cookifyString, getSubCookieNames, getUserCookieName, getUserCookieParametersConfig, getUserFromSubcookies } from './tools';

const migrateCookies = () => {
    const newCookieNames = getSubCookieNames();
    const legacyCookieName = getUserCookieName();
    const userCookieConfig = getUserCookieParametersConfig();

    const legacyCookieData = getCookie(legacyCookieName);
    const idTokenCookieData = getCookie(newCookieNames.idTokenLabel);
    const accessTokenCookieData = getCookie(newCookieNames.accessTokenLabel);
    const refreshTokenCookieData = getCookie(newCookieNames.refreshTokenLabel);
    const userDataCookieData = getCookie(newCookieNames.userData);

    const userFromCookies = getUserFromSubcookies();
    if(userFromCookies)
    {
        const { id_token, access_token, refresh_token, ...userData } = userFromCookies;

        if (legacyCookieData && userFromCookies) {
            const isNotLiteralNullOrUndefined = (value : string) => value !== 'null' && value !== 'undefined';
    
            if (!userDataCookieData && userData) {
                setCookie(newCookieNames.userData, cookifyObject(userData), userCookieConfig);
            }
            if (!idTokenCookieData && id_token && isNotLiteralNullOrUndefined(id_token)) {
                setCookie(newCookieNames.idTokenLabel, cookifyString(id_token), userCookieConfig);
            }
            if (!accessTokenCookieData && access_token && isNotLiteralNullOrUndefined(access_token)) {
                setCookie(newCookieNames.accessTokenLabel, cookifyString(access_token), userCookieConfig);
            }
            if (!refreshTokenCookieData && refresh_token && isNotLiteralNullOrUndefined(refresh_token)) {
                setCookie(newCookieNames.refreshTokenLabel, cookifyString(refresh_token), userCookieConfig);
            }
            
            removeCookie(legacyCookieName, userCookieConfig);
        }
    }
};

function getCookie(name : string) {
    const cookieArray = document.cookie.split(';');
        for (const cookie of cookieArray) {
            const [cookieName, cookieValue] = cookie.trim().split('=');
            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }
    return null;
}

function setCookie(name : string, value : string, options : any) {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    if (options.domain) {
        cookieString += `; ;domain=${options.domain}`;
    }
    if (options.expires) {
        cookieString += `; expires=${options.expires.toUTCString()}`;
    }
    if (options.path) {
        cookieString += `; path=${options.path}`;
    }
    if (options.sameSite){
        cookieString += `; SameSite=${options.sameSite}`
    }
    if (options.secure) {
        cookieString += "; Secure"
    }
    document.cookie = cookieString;
}

function removeCookie(name : string, options : any) {
    const expires = new Date(0);
    setCookie(name, '', { ...options, expires });
}

export {
    migrateCookies
};