import { useEffect, useState } from 'react'
import { AiFillCaretDown } from 'react-icons/ai'
import { BiCheck } from 'react-icons/bi'
import styled from 'styled-components'
import { Button } from '.'
import { Container, Dropdown, Text } from '..'
import StyledDropdownInputRefactorized from './dropdown-input-refatorized-styled'
const Arrow = styled(Container)`

`

export const DropdownInputRefactorized = (props:any) => {
  const {
    trigger, 
    clearStyles, 
    noSelectionMessage, 
    onHover, 
    allowMultipleSelection, 
    hideCaret, 
    selectedOption, 
    onChange: onChangeProps, 
    label, 
    options, 
    width, 
    disabled } = props
  const initialValues = label?.split(',').reduce((acc:any, curr:string) => ({ ...acc, [curr]: true }), {})
  const [ isExpanded, setIsExpanded ] = useState(false)
  const [ values, setValues ] = useState(initialValues)
  const [ message, setMessage ] = useState('')

  useEffect(()=>{
    if(label) setValues(initialValues)
  },[label])
  const onClose = () => {
    setIsExpanded(false)
  }

  const onChange = (item:any) => {
    if(item){
      onChangeProps(item)
    }
    onClose()
  }

  const onUpdateCategories = () => {
    let newValues = ''
    Object.keys(values).forEach((value: string) => { if (values[value]) newValues = `${newValues ? `${newValues},` : ''}${value}` })
    onChangeProps(newValues)
    onClose()
  }

  const renderTrigger = () => {
    if (trigger) {
      return (
        <Container className={'trigger'} onClick={() => ( !onHover && isExpanded) ? onChange(selectedOption) : setIsExpanded(true)}>
          {trigger}
        </Container>
      )
    }
    return (
      <Container expand className={'trigger'} onClick={() => ( !onHover && isExpanded) ? onChange(selectedOption) : setIsExpanded(true)}> 
        {
          label && (
            <Container className={'label'}>
              <Text>{`${label.split(',').length > 1 ? `(${label.split(',').length}) `: ''}${label.replaceAll(',',', ')}`}</Text>
            </Container>
          )
        }
        { selectedOption?.label && <Text>{selectedOption.label}</Text> }
        { selectedOption?.icon }
        { !hideCaret && <Arrow className={`arrow`} ><AiFillCaretDown size={10}/></Arrow> }
      </Container>
    )
  }

  if (allowMultipleSelection) {
    return (
      <StyledDropdownInputRefactorized className={`${clearStyles ? '': 'default '}${isExpanded ? 'active ' : '' } ${disabled ? 'disabled ': ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}> 
          <Container className={'options'}>
            {
              (() =>{
                return (options.map((option:Record<string, any>) => {
                  const onCheckOption = (e:any) => {
                    setValues({ ...values, [option.key]: e.target.checked })
                  }
                  const isChecked = values[option.key] ? true : false
                  return (<Container key={option.key} className={'role'} >
                    <label>
                      <input type={'checkbox'} checked={isChecked} onChange={(e)=>{onChangeProps(option.key,e);onCheckOption(e)}} />
                      <Container className={'checkbox'} >
                        <Container className={'check'}>
                          <BiCheck />
                        </Container>
                      </Container>
                      <Text>{option.label}</Text> 
                    </label>
                  </Container>)
                }))
              })()
            }
            {
              message 
              ? (
                <Container className={'message'}>
                  <Text>{message}</Text>
                </Container>
              )
              : (
                <Button onClick={onUpdateCategories}>
                  <Text>{'Apply'}</Text>
                </Button>
              )
            }
          </Container>
        </Dropdown>
      </StyledDropdownInputRefactorized>
    )
  }

  return (
    <div
    className={`dropdown-input-wrapper`}
    style={{
      display: 'flex',
      width: width || '100%',
      height: '100%',
    }}
      onMouseLeave={() => {
        if (onHover) {
          onHover()
          return setIsExpanded(false)
        }
      }}
      onMouseEnter={() =>  {
        if (onHover) {
          onHover()
          return setIsExpanded(true)
        }
      }}
    >
      <StyledDropdownInputRefactorized className={`${clearStyles ? '': 'default '}${isExpanded ? 'active ' : ''}${disabled ? 'disabled ': ''}dropdown-selector`}>
        <Dropdown onClose={onClose} isExpanded={isExpanded} trigger={renderTrigger}> 
          <Container className={'options'}>
            {options.map((option: Record<string, any>) =>  
              <Container key={option.key} className={`role`} onClick={() => onChange(option)}>
                <Text>{option.label}</Text> 
              </Container>
            )}
          </Container>
        </Dropdown>
      </StyledDropdownInputRefactorized>
    </div>
  )
}

export default DropdownInputRefactorized
