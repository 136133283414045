import React ,{ ReactNode } from 'react'
import styled from 'styled-components'
import { Container } from '../containers'
import { IoMdArrowForward } from 'react-icons/io'
import { ColorType, getColor } from '../../../utils'



interface ArrowButtonProps{
  onClick?: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: ReactNode,
  backgroundColor?: ColorType,
  color?: ColorType 
  className?: any
  disabled?: boolean
  isFetching?: boolean
}

const StyledArrowButton = styled.button`
    background-color: ${({backgroundColor}: ArrowButtonProps) => getColor(backgroundColor || 'brand')};
    color: ${({color}: ArrowButtonProps)=> getColor(color || 'text')};
    border: none;
    font-weight: bold;
`

export default function ArrowButton(props: ArrowButtonProps) {
  const {
    children,
    onClick,
    isFetching,
    backgroundColor,
    color,
    disabled,
    className
  } = props
  return (
    <StyledArrowButton backgroundColor={backgroundColor} color={color} disabled={isFetching || disabled} onClick={onClick && ((e)=>onClick(e))} className={`${className || ''}`}>
      {children}
    </StyledArrowButton>
  )
}
