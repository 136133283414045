import React from 'react'
import styled from 'styled-components'

const StyledCheckboxInput = styled.label`
  display: flex;
`

export const CheckboxInput = (props: any) => {
  return (
    <StyledCheckboxInput className='checkbox'>
      <input type={'checkbox'} onChange={({target: { checked }}:any) => props.onChange(checked)} checked={props.value} disabled={props.isDisabled} />
      {props.children}
    </StyledCheckboxInput>
  )
}

export default CheckboxInput
