import { Container, Text } from "components/shared"
import BenefitItemStyled from "./benefit-item-styled"

const BenefitItem = ({icon,text,className}: {icon: any, text: string, className: string}) => {
    return (
    <BenefitItemStyled className={className}>
        <img src={icon}/>
        <Text textStyle="h5">{text}</Text>
    </BenefitItemStyled>)
}

export  default BenefitItem