import styled from "styled-components";
import { Container } from "components";

const WorbookSendExportStyled = styled(Container)`
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 24px 32px;
    .title-container{
        background-color: white;
        p{
            padding: 20px 0px;
        }
    }
    .workbook-send-options-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        a{
            display: flex;
            text-decoration: none;
            align-items: center;
        }
        p{
            display: inline-block;
            padding-left: 10px;
        }
    }
`

export default WorbookSendExportStyled