import Container from '../../../shared/containers/container'
import { Text } from '../../../shared'
import { ErrorIcon, LoadingSpinner } from '../../../../assets'
import { Button } from '../../../shared/inputs'
import { useEffect } from 'react'
import { IStack } from 'utils/constants/interfaces'
import { StyledSnack } from './snack-styled'

export const Snack = ({data, onClose:propsOnClose}: { data: IStack, onClose: () => void }) => {
  const isActivityIndicator = data.type === 'activityIndicator'
  const autoClose = () => {
    if (!isActivityIndicator && !data.persist) {
      return setTimeout(() => onClose(), 5000)
    }
  };  
    
  useEffect(() => {
    autoClose()
  }, [])
  

  const onClose = () => {
    propsOnClose()
  }

  return (
    <StyledSnack className={`${data.visible ? 'visible' : 'hidden' } snack`}>
      <Container expand className={'snack-text'}>
        <Text color='white'>{data.message}</Text>
      </Container>
      <Container className={'close-button'}>
        { !isActivityIndicator
          ? (
            <Button onClick={onClose}>
              <ErrorIcon />
            </Button>
          ) : (
            <LoadingSpinner />
          )
        }
      </Container>
    </StyledSnack>
  )
}

export default Snack