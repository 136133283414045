import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components';

interface ContainerProps {
  children?: ReactNode,
  className?: String,
  bordered?: boolean,
  expand?: boolean,
  flex?: number,
  REF?: any,
  onClick?: (e?:any) => void,
  disabled?: boolean
  onMouseLeave?: (e?:any) => void,
}

const StyledContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  ${({ bordered }: ContainerProps) => bordered && css`border: 1px solid;`}
  ${({ expand }: ContainerProps) => expand && css`height: 100%; width: 100%;`}
  ${({ flex }: ContainerProps) => flex && css`flex: ${flex};`}
  ${({ onClick }: ContainerProps) => onClick && css`cursor: pointer;`}  
`

export const Container = (props:ContainerProps) => {
  const {
    children,
    className,
    onClick,
    onMouseLeave,
    REF,
  } = props
  return (
    <StyledContainer
      ref={REF}
      {...props}
      className={`${className || 'simple'}-container`}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </StyledContainer>
  )
}

export default Container;