import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Text } from '..'
import { ColorType, getColor } from '../../../utils'
import { devices } from '../../../utils/constants/media-queries'

interface TimeInputProps {
  placeholder?: string,
  initialValue?: any,
  value?: string,
  label?: string,
  textOnly?: boolean,
  multiline?: boolean,
  onChange?: any,
  onClick?: React.MouseEventHandler<HTMLInputElement>
  onFocus?: (e:React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e:React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
  type?: string,
  color?: ColorType,
  background?: ColorType
}

const StyledInput = styled(Container)`
  position: relative;
  input, textarea {
    font-family: 'GilroyRegular';
    padding: 8px 15px;
    width: 100%;
    border-radius: 12px;
    outline: none;
    background: ${({background}:TimeInputProps) => background ? getColor(background) : getColor('white')};
    border: 1px solid ${getColor('border')};
    &::placeholder { 
      color: ${({color}:TimeInputProps) => color ? getColor(color) : getColor('textClear')};
    }
  }
  .day-selector-container {
    margin-top: 10px;
    width: 100%;
    .day-container {
      height: auto;
      width: 40px;
      border-radius: 50%;
      background: ${getColor('grayClear')};
      &.selected {
        background: ${getColor('brand')};
        p {
          color: ${getColor('white')};
        }
      }
    }
  }
  .label-container {
    position: absolute;
    background: ${getColor('white')};
    &.transparent-background {
      background: transparent;
    }
    top: -12px;;
    padding: 0 4px;
    left: 12px;
    p {
      font-weight: 400;
      font-size: 11px;
    }
  }
  @media only screen and ${devices.tablet}{
    .day-selector-container{
      .day-container{
        height: 40px;
        width: 40px;
      }
    }
  }
`

export const TimeInput = ({
  placeholder,
  value,
  type,
  label,
  onChange,
  initialValue,
  color,
  onClick,
  onFocus,
  onBlur,
  onKeyDown,
}: TimeInputProps) => {
  const days = [
    {label: 'S', value: '0'},
    {label: 'M', value: '1'},
    {label: 'T', value: '2'},
    {label: 'W', value: '3'},
    {label: 'T', value: '4'},
    {label: 'F', value: '5'},
    {label: 'S', value: '6'},
  ]
  const [selectedDay, setSelectedDay] = useState('')

  useEffect(() => {
    if (!selectedDay && initialValue) {
      setSelectedDay(`${initialValue}`)
    }
    if (onChange && selectedDay) {
      onChange(selectedDay)
    }
  }, [selectedDay])
  

  if (type === 'day' ){
    return (
      <StyledInput className={'text-input'}>
        {label && <Container className={'transparent-background label'}>
          <Text>
            {label}
          </Text>
        </Container>}
        <Container className={'day-selector'}>
          {
            days.map(({label, value}:any) => <Container className={`${selectedDay === value ? 'selected ' : ''}day`} onClick={()=> setSelectedDay(value)} key={value+'container'}>
              <Text>
                {label}
              </Text>
            </Container>)
          }
        </Container>
      </StyledInput>
    )
  }
  
  return (
    <StyledInput className={'text-input'}>
      {label && <Container className={'label'}>
        <Text>        
          {label}
        </Text>
      </Container>}
      <input
        placeholder={placeholder}
        type='time'
        value={value}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    </StyledInput>
  )
}
export default TimeInput