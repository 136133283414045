import { DropdownInputProps, DropdownInputPropsv2 } from "./interfaces";

export const dropdownPropsv2: DropdownInputPropsv2 = {
    selectedValues: [],
    onChange: ()=>{},
    options: [],
    allowMultipleSelection: false,
    allowSingleSelection: false,
    filterSettingGuid:'',
    sortBy: '',
    filterName: '',
}

export const  dropdownProps: DropdownInputProps = {
    label : '',
    trigger : undefined,
    options : [],
    onHover : undefined,
    noSelectionMessage : '',
    staticRanges: [],
    hideCaret : false,
    isRangeDropdownInput : false,
    allowSingleSelection : false,
    allowMultipleSelection : false,
    disabled : false,
    isDropdownActionsActive : false,
    clearStyles : false,
    width: undefined,
    selectedOption : { icon: undefined, label: ''},
    filterSettingGuid : '',
    isValueRequired : true,
    onChange: ()=>{},
    filterSearchedValueInList : ()=>{},
    resetFilteredValues : ()=>{},
    restoreSelectedValues : ()=>{},
    setSearchedText :()=>{},
    searchedText: "",
}