import styled from "styled-components";
import { Container } from "../../../../shared";

const StyledWorkbookFilter = styled(Container)`
    flex-direction: column;
    .dropdown-title-container{
        display: flex;
        padding: 0px 0px 5px 0px;
        align-items: center;
        justify-content: left;
        gap: 5px;
        .h4{
            display: inline-block;
        }
        button{
            &.info-icon{
                .tooltip-trigger-container{
                    .tooltip-container{
                        top: -55px;
                        left: 0px;
                        height: 50px;
                        width: 500px;
                        p{
                            text-align: justify;
                            white-space: pre-line;
                        }
                    }
                }
            }
            width: fit-content;
            padding: 0;
            .tooltip-trigger-container{
                position: absolute;
                .tooltip-container{
                    top: 32px;
                    p.h7{
                        font-size: 12px;
                    }
                }
            }
        }
    }
    >div{
      width: 100%;
    }
`

export default StyledWorkbookFilter