import styled from "styled-components"
import { Container } from 'components'
import { getColor } from "utils"
import { devices } from 'utils/constants/media-queries'



const StyledPrivateRoute = styled(Container)`
  flex-direction: column;
  max-height: 100%;
  .body-container {
    position: fixed;
    top: 73px;
    display: block;
    background: rgb(251, 250, 255);
    overflow: auto;
    width: 100%;
    height: calc(100% - 144px);
    flex: 1;
    .dashboard-container{
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
  .MuiBox-root{
    padding-top: 8px;
  }
  @media only screen and ${devices.tablet}{
    .body-container{
      overflow: hidden;
      position: relative;
      top: 0;
      .dashboard-container{
        border-top-right-radius: 32px;
        border-top-left-radius: 32px;
      }
    }
    .MuiBox-root{
      display: none;
    }
  }

  @media only screen and ${devices.laptop}{
    .body-container{
      background: ${getColor('brandDark')};
        dashboard-container{
          border-top-right-radius: 32px;
          border-top-left-radius: 32px;
        }
    }
  }
`

export default StyledPrivateRoute