import { getColor } from '../../../../utils'
import { Container } from 'components'
import styled from 'styled-components'

const StyledWorkbookDownload = styled(Container)`
  width: 100%;
  .download-list-container {
    display: inline-block;
    flex-direction: column;
    width: 100%;
    padding-right: 18px;
    .workbook-element-row-container {
      &:not(:nth-last-of-type(1)) {
        border-bottom: 1px solid ${getColor('border')};
      }
      width: 100%;
      justify-content: space-between;
      padding: 16px 0;
      p {
        font-weight: 500;
      }
      .workbook-element-download-options-container {
        button {
          background: transparent;
        }
        svg {
          color: ${getColor('brandDark')};
        }
      }
    }
  }
`

export default StyledWorkbookDownload