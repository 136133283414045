import { createReducer, SerializedError } from '@reduxjs/toolkit'
import actions from './actions'

export type DataTrackingState = {
  isFetching: boolean
  error?: SerializedError
  message?: any
  application: string
  environment: string
}

const initialState: DataTrackingState = {
  isFetching: false,
  application: '',
  environment: '',
}

const dataTrackingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.trackingInit.pending, (state) => {
      state.isFetching = true
    })
    .addCase(actions.trackingInit.rejected, (state) => {
      state.isFetching = false
    })
    .addCase(actions.trackingInit.fulfilled, (state, action) => {
      state.application = action.payload.application
      state.isFetching = false
      state.environment = `${action.payload.environment}`
    })
    .addCase(actions.trackEvent.pending, (state) => {
      state.isFetching = true
    })
    .addCase(actions.trackEvent.rejected, (state) => {
      state.isFetching = false
    })
})

export default dataTrackingReducer
