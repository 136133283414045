import WorkbookSendExportStyled from './workbook-send-export-styled'
import { Text, Container } from '../../shared'
import { Link } from 'react-router-dom'
import { IoMdCalendar, IoMdSend } from 'react-icons/io'
import { getColor } from '../../../utils'
import { hooks, mainOperations } from 'state';


export default function WorkbookSendExport() {
  const { useAppSelector, useAppDispatch } = hooks
  const dispatch = useAppDispatch()
  const { setMainContent }= mainOperations
  return (
    <WorkbookSendExportStyled className='workbook-send'>
      <Container className='workbook-send-options'>
        <Container onClick={()=>{dispatch(setMainContent('sendNow'))}}>
            <IoMdSend color={getColor('brand')()} size={'20px'}/>
            <Text textStyle='h4'>
                Send Now
            </Text>
        </Container>
        <Container onClick={()=>{dispatch(setMainContent('schedule'))}}>
            <IoMdCalendar color={getColor('brand')()} size={'20px'}/>
            <Text textStyle='h4'>
            Schedule
            </Text>
        </Container>

      </Container>
    </WorkbookSendExportStyled>
  )
}
