import styled from 'styled-components'
import { Container } from 'components'
import { devices } from 'utils/constants/media-queries';
const StyledError = styled(Container)`
  height: 100vh;
  padding: 16px;
  justify-content: flex-start;
  overflow: auto;
  box-sizing: border-box;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  .title-section-container {
    padding: 32px;
    padding-top: 16px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: space-between;
    @media only screen and ${devices['2xl']}{
      padding-top: 56px;
    }
    p.h1 {
      color: #1932a0;
      font-size: 32px;
      line-height: 54px;
      font-family: 'GilroyRegular';
      font-weight: normal;
      @media (max-width: 768px) {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 32px;
      }
    }
    p.h3 {
      text-align: center;
      color: #1932a0;
      font-size: 24px;
      line-height: 24px;
      :first-of-type{
        font-family: 'GilroyRegular' !important;
        font-weight: 400;
        font-size: 26px;
        line-height: 50px;
        @media only screen and ${devices['2xl']}{
          font-size: 36px;
        }
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p.h4 {
        text-align: start;
    }
  }
  .actions-section-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 22px;
    @media only screen and ${devices['2xl']}{
      gap: 32px;
    }
    justify-content: flex-start;
    align-content: baseline;
    .background-container {
      position: absolute;
      z-index: -1;
      width: 100%;
      display: flex;
      flex: 1;
      bottom: 0;
      img {
        width: 100%;
      }
    }
  }
  button {
    background: #6E51F6;
    &:hover {
      background: #6E51F69b;
    }
    p {
      color: white;
      font-size: 18px;
      
    }
  }
  p {
    margin: 0;
    text-align: center;
  }
  img {
    height: 284px;
    width: 704px;
    @media only screen and ${devices['2xl']}{
      height: 384px;
    }
  }
`;

export default StyledError