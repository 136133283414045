import { ReactNode } from "react"
import styled, { css } from "styled-components"
import { getColor } from "utils"
interface ISortOptionsProps {
    children?: ReactNode,
    sortBy?: string
    onMouseLeave?: (e?:any) => void,
}

const StyledSortOptions = styled.div`
    flex-flow: column nowrap;
    .sort-by-option-container{
        width: 100%;
        p{margin: 0; font-size: 14px}
        justify-content: start;
        padding: 6px 8px;
        &.default-container{
            background-color: ${({sortBy}:ISortOptionsProps)=> sortBy ? '#e4e6f7' :'none'};
            color: ${getColor('brand')};
            :hover{
                background-color: #e4e6f7
            }
        }
        :hover{
            background-color: #F7F7F7;
        }
        :active{
            background-color: #e4e6f7;
            color: ${getColor('brandDarkHight')}
        }
    }
`

export const SortingOptions = ({children,...props}:ISortOptionsProps) => {
    return (<StyledSortOptions {...props}>
            {children}
        </StyledSortOptions>)
}

export default SortingOptions