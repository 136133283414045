import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import RangeOptions from "./range-options"
import StyledDateRangeContent from "./date-range-content-styled";
import { ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import OptionContentDisplay from "./option-content-display";
import DateInput from "../date-input";
import { subtractMonths, getDatePickerDefaultValues, formatDate } from "utils"

interface DateRangeContentProps{
    isContentExpanded: boolean,
    setIsContentExpanded: () => undefined,
    selectedOption: string,
    setSelectedOption: React.Dispatch<React.SetStateAction<string | 'Between'>>,
    setLabel: React.Dispatch<React.SetStateAction<string>>
    resetValues: () => void
    rangeValues: Range[]
    setRangeValues:  (v:Range[],a:string)=> undefined
}

const rangeLabels = [
    {
        label: 'Between',
    },
    {
        label: 'Last'
    }
]

const optionsDate = [
    {
        label: "Days"
    },
    {
        label: "Months"
    }
]
const DateRangeContent = ({isContentExpanded, selectedOption, setSelectedOption, setLabel, resetValues, setIsContentExpanded, rangeValues, setRangeValues}: DateRangeContentProps) =>{
    const selectedOptionLC = selectedOption.toLocaleLowerCase()
    const [ inputValue, setInputValue ] = useState('2')
    const [ dateTimeSelection, setDateTimeSelection ] = useState('Days')
    const { minDate, maxDate, shownDate, disabledDates } = useMemo(()=>{
        return getDatePickerDefaultValues()
    },[])

    useEffect(()=>{
        if(isContentExpanded && selectedOption === 'Last'){
            resetValues()
            handleDateSelectedOption(dateTimeSelection,inputValue)
        }
    },[isContentExpanded,selectedOption])
    
    const formatGMTTimestampToISO = (gmtTimestamp: Date | undefined) => {
        if(gmtTimestamp){
            let isoTimestamp = gmtTimestamp.toISOString().substring(0,19) + 'Z';
            return isoTimestamp
        }
        return undefined
    }

    const applyValidationBySelectedOption = (selection: Range) => {
        if(selectedOption === 'Between'){
            if(selection.startDate && selection.endDate){
                let formattedStartDateTimestampISO = formatGMTTimestampToISO(selection.startDate)
                let formattedEndDateTimestampISO = formatGMTTimestampToISO(selection.endDate)
                if(formattedEndDateTimestampISO && formattedStartDateTimestampISO ){
                    const selectionFormatted: Range = {
                        endDate: new Date(formattedEndDateTimestampISO),
                        startDate: new Date(formattedStartDateTimestampISO),
                        key: 'selection'
                    }
                    const formattedStartDate = formatDate(selection.startDate, true, true,'/')
                    const formattedEndDate = formatDate(selection.endDate, true, true,'/')
                    setLabel(`${formattedStartDate}-${formattedEndDate}`)
                    if(formattedStartDateTimestampISO === formattedEndDateTimestampISO){
                        setRangeValues([selectionFormatted], '')
                        return
                    }
                    setRangeValues([selectionFormatted], `min:${formattedStartDateTimestampISO},max:${formattedEndDateTimestampISO}`)
                }

            }
        }
    }

    const handleRangeChange = (o: RangeKeyDict) => {
        setLabel('')
        const { selection }: RangeKeyDict = o
        applyValidationBySelectedOption(selection)
    }

    const handleDateSelectedOption = (val:SetStateAction<string>,timePeriod: string | undefined) => {
        resetValues()
        if(dateTimeSelection !== val.toString()){
            setInputValue('')
            setLabel('')
            setDateTimeSelection(val.toString())
            return
        }
        setDateTimeSelection(val.toString())
        let startDate = new Date()
        let endDate = new Date ()
        const timeLapse = Number(timePeriod ? timePeriod : inputValue)
        const charSeparator = '/'
        if(val === 'Days'){
            startDate.setDate(startDate.getDate() - timeLapse)
            endDate.setDate(endDate.getDate() - 1)
            const formattedStartDate = formatDate(startDate, true, false, charSeparator);
            const formattedEndDate = formatDate(endDate,true, false, charSeparator);
            
            setLabel(`Last ${timePeriod ? timePeriod : inputValue} days ( ${formattedStartDate} - ${formattedEndDate} )`)
        }else if(val === 'Months'){
            const startDateModified = subtractMonths(startDate, timeLapse)
            const endDateModified = subtractMonths(endDate, 0)
            startDate = startDateModified
            endDate = endDateModified
            const formattedStartDate = formatDate(startDateModified, false, true,charSeparator);
            const formattedEndDate = formatDate(endDateModified,false, true,charSeparator)
            let labelSub = ''
            if(timePeriod){
                labelSub = Number(timePeriod) > 1 ? `${timePeriod} months` : `month`
            }else{
                labelSub = Number(inputValue) > 1 ? `${inputValue} months` : `month`
            }
            if(formattedStartDate === formattedEndDate){
                setLabel(`Last ${labelSub} (${formattedStartDate})`)
                return 
            }
            setLabel(`Last ${labelSub} (${formattedStartDate}-${formattedEndDate})`)
        }
        const selectionFormatted = {
            endDate: new Date(endDate),
            startDate: new Date(startDate),
            key: 'selection'
        }
        setRangeValues([selectionFormatted], `min:prior-${val.toString().toLowerCase()}-${timeLapse === 1 ? '0' : timeLapse},max:prior-${val.toString().toLowerCase()}-${timeLapse ===1 ? '0':'1'}`)

    }
    const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rEx = new RegExp('\\d+$')
        const { value } = e.target
        const  vN = Number(value)
        if(rEx.test(value) && value.length < 3){
            if(vN <= 3 && vN !== 0 && dateTimeSelection === 'Months'){
                setInputValue(value)
                handleDateSelectedOption(dateTimeSelection,value)
            }else if(vN > 0 && vN <= 90 &&  dateTimeSelection === 'Days'){
                setInputValue(value)
                handleDateSelectedOption(dateTimeSelection,value)
            }
        }else if(value === ''){
            setInputValue(value)
        }
    }
    const optionContent: { node: ReactNode, minWidth: string } | undefined = (()=>{
        if(selectedOptionLC === 'between'){
            return(
                {
                    node: (<DateRangePicker minDate={minDate} maxDate={maxDate} shownDate={shownDate} disabledDates={disabledDates} months={2} showDateDisplay={false} direction="horizontal" ranges={rangeValues} onChange={handleRangeChange}/>),
                    minWidth: '800px',
                }
            )
        }
        if(selectedOptionLC === 'last') 
            return(
                {
                    node: (<DateInput value={inputValue} onChange={onChangeInputValue} children={<RangeOptions options={optionsDate} isContentExpanded={true} setSelectedOption={(va)=>{handleDateSelectedOption(va,inputValue)}} selectedOption={dateTimeSelection}/>}/>),
                    minWidth: '400px',
                })
    })()
    return(
        <StyledDateRangeContent direction={selectedOptionLC === 'last' ? 'row' : 'column'} className={`date-range-content-container ${isContentExpanded ? 'expanded' : 'colapsed'}`}>
            <div style={{width: '100px'}}>
                <RangeOptions isContentExpanded={isContentExpanded} selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={rangeLabels}/>
            </div>
            {
               optionContent && <OptionContentDisplay minWidth={optionContent.minWidth}>{optionContent.node}</OptionContentDisplay>
            }
        </StyledDateRangeContent>
    )
}

export default DateRangeContent;