import { useState, useRef } from "react"
import StyledDateRangePicker from "./date-range-picker-styled"
import { Container, Text } from "components"
import CalendarIcon from 'assets/icons/calendar_icon'
import DateRangeContent from "./date-range-content"
import { IoClose } from "react-icons/io5"
import { useClickOutside, formatDate } from "utils"
import { Range } from "react-date-range";
import { selectedDateRangeValue } from 'utils/constants/interfaces'
const DateRangePicker = ({ title, setSigmaControlValue}:{title: string, setSigmaControlValue: React.Dispatch<React.SetStateAction<selectedDateRangeValue>>}) => {
    const [ isContentExpanded, setIsContentExpanded ] = useState(false)
    const [ selectedOption, setSelectedOption ] = useState('Between');
    const [ label, setLabel ] = useState("");
    const [ rangeValues, setRangeValues ] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }] as Range[])

    const dropdownRef = useRef(null)

    const resetValues = () => {
        setRangeValues([{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }])
        setLabel("")
        setSigmaControlValue({
            dateRangeSelected: '',
            sigmaFormat: ''
        })
    }
    const handleOnClick = () => {
        setIsContentExpanded(!isContentExpanded)
        return undefined
    }

    const handleRangeValuesSelected = (v: Range[],a: string) =>{
        let formattedStartDate = formatDate(v[0].startDate!, true, true, '-')
        let formattedEndDate = formatDate(v[0].endDate!, true, true, '-')
        setSigmaControlValue({
            dateRangeSelected: `${formattedStartDate}-${formattedEndDate}`,
            sigmaFormat: a,
        })
        setRangeValues(v)
        return undefined
    }
    useClickOutside(dropdownRef, () => setIsContentExpanded(false)  )
    return(<StyledDateRangePicker className={'dater-range-picker'} REF={dropdownRef}>
        <Text textStyle="h4">{title}</Text>
        <Container className={`${isContentExpanded ? 'bordered':''} label`} onClick={handleOnClick}>
            <CalendarIcon width={18} color={"#736a8c"}/>
            <p style={{color: `${label.length ? "#000":"#736a8c"}`}}>{`${label ? label : 'Select date range'}`}</p>
            <button style={{ border: 'none', cursor: 'pointer' }} className={`x-icon ${true ? 'visible' : 'hidden'}`} onClick={(e) => {
                e.stopPropagation()
                resetValues()
            }}>
              <IoClose />
            </button>
        </Container>
        <DateRangeContent rangeValues={rangeValues} setRangeValues={(val,a)=>handleRangeValuesSelected(val, a)} isContentExpanded={isContentExpanded} setIsContentExpanded={handleOnClick} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setLabel={setLabel} resetValues={resetValues}/>
    </StyledDateRangePicker>)
}

export default DateRangePicker