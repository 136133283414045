import { Button, Container, DropdownInputv2, Text } from 'components/shared'
import { useEffect, useState } from 'react'
import { IFilter, IFilterValue } from 'utils/constants/interfaces'
import { InfoIcon } from 'assets'
import StyledWorkbookFilter from './workbook-filter-styled'
import { filterTooltipLabel, stateTooltipLabel } from 'utils/constants'
interface IWorkbookFilter  {
    filter: IFilter,
    onChange: (values:IFilterValue[],sortBy:string)=>{},
    restoreSelectedValues: ()=>void,
    disabled: boolean,
    hasInfoIcon: boolean
}

const WorkbookFilter = ({ filter, onChange, restoreSelectedValues, disabled, hasInfoIcon}: IWorkbookFilter)=>{
    const [filterR, setFilterR] = useState({} as IFilter)
    useEffect(()=>{
        setFilterR(filter)
    },[filter.filterValues])
    
    const getFilterLabelSelected = ()=>{
        const selectedFilters = filterR?.filterValues?.filter((filterValue:IFilterValue)=>{
            return filterValue.isSelected
        })
        if(selectedFilters && selectedFilters.length > 0){
            return selectedFilters
        }
        return []
    }

    const getFilterValues = (filterValues:IFilterValue[]) =>{    
        return filterValues.map((filterValue:IFilterValue)=>{
            return({
                label: filterValue.value,
                id: filterValue.id
            })
        })
    }

    const filterSelectedValues = getFilterLabelSelected()
    const filterValues = getFilterValues(filter.filterValues)
    return(<StyledWorkbookFilter className={'workbook-filter'}>
        <Container className={'dropdown-title'}>
            <Text textStyle='h4'>{filter.filterDisplayName}</Text>
            { disabled && <Button tooltip={{label:filterTooltipLabel, position: 'top'}}><InfoIcon style={{display: 'inline-block', color:'#736a8c'}} width={15} height={15}/></Button>}
            { hasInfoIcon &&  <Button  className="info-icon" tooltip={{label:stateTooltipLabel, position: 'top'}}><InfoIcon style={{display: 'inline-block', color:'#736a8c'}} width={15} height={15}/></Button>}
        </Container>
        <DropdownInputv2
            selectedValues={filterSelectedValues}
            allowMultipleSelection={filter.allowMultipleSelection}
            options={filterValues}
            onChange={onChange}
            filterSettingGuid={filter.filterSettingGuid}
            restoreSelectedValues={restoreSelectedValues}
            sortBy={filter.order}
            filterName={filter.filterDisplayName}
            showClearButton={filter.showClearButton}
            isValueRequired={filter.isRequired}
            disabled={disabled}
        />
    </StyledWorkbookFilter>)
}

export default WorkbookFilter