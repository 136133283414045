import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

interface LinkProps {
  to: string
  children?: ReactNode
}

const StyledLink = styled(RouterLink)`
  text-decoration: none;
  display: flex;

`

export const Link = (props: LinkProps) => {
  const { to, children } = props
  return (
    <StyledLink to={to}>{children}</StyledLink>
  )
}
export default Link