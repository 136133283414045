import React from 'react'
import styled from 'styled-components'
import { getColor } from '../../../../utils'
import { Container } from 'components/shared/containers/container'
import { SortInterface } from './interfaces'

const StyledSortIcon = styled(Container)` 
  button.sort-button{
    position: relative;
    border: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #0A0A0A;
    cursor: pointer;
    display: flex;
    padding: 0px;
    height: 20px;
    &:hover{
      background-color: #DDDDDD;
      border-radius: 5px;
    }
    &[aria-disabled="false"] svg{
      opacity: 0.85;
    }
    .svg-1st{
      pointer-events: none;
      width: 20px;
      height: 16px;
      flex-shrink: 0;
    }

    .svg-2nd{
      pointer-events: none;
      height: 20px;
      width: 12px;
    }
  }
`

const SortIcon = ({isArrowVisible = false, isAscending = false, isAlphaNumeric, width, marginRight, onClick}:SortInterface)=>{
  return (
    <StyledSortIcon className='sort-icon'>
    {
      isAlphaNumeric ? 
      <button aria-disabled="false" data-active="false" data-track-id="button-sort-az" className='sort-button' onClick={onClick}>
            {
              isAscending ? 
              <svg style={{marginRight:`${marginRight}`}} width={width} fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title></title><path fill="currentColor" d="M19.24 16.57a.75.75 0 0 0-1 0L17 17.78V4.34a.75.75 0 0 0-.75-.75.76.76 0 0 0-.75.76v13.48l-1.27-1.21a.71.71 0 0 0-.53-.21.76.76 0 0 0-.51.23.74.74 0 0 0-.21.53.78.78 0 0 0 .23.51l2.62 2.5a.63.63 0 0 0 .43.17.61.61 0 0 0 .43-.18l2.56-2.55a.74.74 0 0 0-.01-1.05zM4.55 11.41L7.1 3.56h1.63l2.56 7.85H9.83l-.62-2.13H6.57L6 11.41zm2.62-4.18l-.27 1h2l-.27-1c-.13-.42-.25-.84-.37-1.23S8 5.07 7.92 4.64c-.11.44-.22.88-.34 1.31s-.28.86-.41 1.28zm-2.4 13.21v-.84l3.8-5.83H5.12v-1.18h5.17v.84l-3.8 5.84h3.83v1.17z"></path></svg>
              :
              <svg style={{marginRight:`${marginRight}`}} width={width} fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title></title><path fill="currentColor" d="M19.53 16.57a.76.76 0 0 0-1.05 0l-1.2 1.21V4.34a.76.76 0 0 0-.75-.75.76.76 0 0 0-.75.76v13.48l-1.27-1.21a.72.72 0 0 0-.53-.21.78.78 0 0 0-.52.23.73.73 0 0 0-.2.53.69.69 0 0 0 .23.51l2.61 2.5a.65.65 0 0 0 .43.17.62.62 0 0 0 .44-.18l2.56-2.55a.76.76 0 0 0 0-1.05zM4.26 20.44l2.56-7.85h1.63L11 20.44H9.54l-.61-2.12H6.29l-.62 2.12zm2.63-4.17l-.28 1h2l-.28-1c-.13-.42-.25-.85-.37-1.29s-.23-.87-.34-1.31L7.25 15zm-2.03-4.86v-.84l3.79-5.84H5.21V3.56h5.17v.84l-3.81 5.83h3.83v1.18z"></path></svg>
            }
            { isArrowVisible &&
              <svg width={'12'} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24"><title></title><path d="M12 14l-4-4h8l-4 4z" fill="currentColor"></path></svg>
            }
      </button>
      :
      <button aria-disabled="false" data-active="false" data-track-id="button-sort-az" className='sort-button' onClick={onClick}>
            {
              isAscending ? 
                <svg style={{marginRight:`${marginRight}`}} width={width} fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title></title><path fill="currentColor" d="M4 18h1V2H4zM7 6h3V4H7zm0 5h6V9H7zm0 5h9v-2H7z"></path></svg>
              :
                <svg style={{marginRight:`${marginRight}`}} width={width} fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="css-1660psx"><title></title><path fill="currentColor" d="M4 18h1V2H4zM7 6h9V4H7zm0 5h6V9H7zm0 5h3v-2H7z"></path></svg>      }
            { isArrowVisible && 
              <svg width={'12'} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24"><title></title><path d="M12 14l-4-4h8l-4 4z" fill="currentColor"></path></svg>
            }
      </button>
    }
    </StyledSortIcon>
  )
}

export default SortIcon
