import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ColorType, getColor } from '../../../utils'
import { Container } from '..'
import { TextInput } from '.'
import { Image, Text } from '../ui-elements'
import { CsvIcon } from 'assets'

interface UploadInputProps {
  value?: FileList,
  onChange?: (e:any) => void,
  accept: string,
  allowMultiple?: boolean
}

const StyledUploadInput = styled(Container)`
  label {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    input {
      display: none;
    }
  }
  .drop-area-container {
    max-width: 450px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px dashed ${getColor('brand')};
    border-radius: 16px;
    flex-direction: column;
    padding: 16px;
    svg {
      height: 42px;
      width: 42px;
      .lines {
        fill: ${getColor('brand')};
      }
    }
  }
`

export const UploadInput = ({ accept, allowMultiple, onChange, value}: UploadInputProps) => {
  const [dropError, setDropError] = useState('')
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const [fileContent, setFileContent] = useState<any>()
  const filetypes:any = {
    csv: {
      label: 'Select a CSV file',
      icon: <CsvIcon />,
      fileType: "text/csv"
    }
  }

  const handleDragOver = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };


  const handleDrop = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files

    if (1 < files.length && !allowMultiple) {
      setDropError(`Only 1 file can be uploaded at a time`);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (file.name.split('.').pop() !== accept) {
        setDropError(`Only ${accept} file format is acceptable`);
        return;
      }
    }

    if (files && files.length) {
      setDropError('');
      setFileContent(files);

    }
  };

  const onSelectFile = (e:ChangeEvent<HTMLInputElement>) => {
    setFileContent(e.target.files)
    setDropError('')
  }

  useEffect(() => {
    if (fileContent && onChange) {
      onChange(fileContent)
    }
  }, [fileContent])

  useEffect(() => {
    const files = []
    if (value) {
      const fileListLength = value.length;
      for (let i = 0; i < fileListLength; i++) {
        files.push( `${value.item(i)?.name}`)
      }
    }
    setSelectedFiles(files)
  }, [value])
  
  
  return (
    <StyledUploadInput expand className={"drag-and-drop-input"}>
      <label>
        <input type="file" accept={filetypes[accept].fileType} onChange={(e) => onSelectFile(e)} multiple={allowMultiple}/>
        <div onDrop={handleDrop} onDragOver={handleDragOver} className={`drop-area-container`}>
          { filetypes[accept].icon }
          { selectedFiles.length < 1 && (
            <>
              <Text textStyle='h7' color='brand'>{filetypes[accept].label}</Text>
              <Text textStyle='h7'>or drag and drop it here</Text>
            </>
          ) }
          { selectedFiles.map((file, i) => <Text key={file} textStyle='h7' color='brand'>{`${file}${selectedFiles.length === (i + 1) ? '' : ','}`}</Text>) }
          {dropError && <Text textStyle='h7' color='error'>{dropError}</Text>}
        </div>
      </label>
    </StyledUploadInput>
  )
}
export default UploadInput