import { Container } from "components";
import * as React from "react"
import { SVGProps } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import styled, { keyframes } from "styled-components"
import { getColor } from "../../utils/theme";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSvgComponent = styled.div`
  display:flex;
  animation: ${spin} 1s infinite;
  border: 2px solid white;
  border-top: 2px solid transparent;
  border-radius: 50%;
  height: 100%;
  max-height: 40px;
  width: 100%;
  max-width: 25px;
`

const SvgComponent = (props: any) => (
  <StyledSvgComponent className={'loading-spinner'}>
  </StyledSvgComponent>
)

export default SvgComponent
