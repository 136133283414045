import styled from 'styled-components'
import { Container } from 'components/shared'

const BenefitItemStyled = styled(Container)`
    width: auto;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
`

export default BenefitItemStyled