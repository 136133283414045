import styled , { keyframes }from 'styled-components'
import Container from 'components/shared/containers/container'
import { getColor } from '../../../../utils'

const showUp = keyframes`
  from {
    opacity: 0;
    margin-top: -40px;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
  }
`;

const hide = keyframes`
  to {
    opacity: 0;
    margin-top: -40px;
    transform: translateY(100%);
  }
`;


export const StyledSnack = styled(Container)`
  &.visible {
    animation: ${showUp} .3s ease forwards;
    display: flex;
  }
  &.hidden {
    animation: ${hide} .3s ease forwards;
    display: none;
  }
  width: 100%;
  padding: 8px 16px;
  flex-direction: row;
  border-radius: 8px;
  background: ${getColor('darkOverlay')};
  .snack-text-container {
    flex: 1;
    justify-content: flex-start;
  }
  .close-button-container {
    height: 24px;
    width: 24px;
    button {
      flex: unset;
      background: none;
      padding: 0;

      path {
        fill: ${getColor('white')};
      }
    }
  }

`