import styled from 'styled-components'
import { getColor } from 'utils'
import { Container } from '../..'
import { devices } from '../../../utils/constants/media-queries';

const StyledWorkbookExport = styled(Container)`
  padding: 32px;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: fit-content;
  overflow-y: auto;
  .row-container {
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    &.message-container{
      justify-content: flex-start;
    }
    &:nth-of-type(1){
      flex-direction: column;
      .text-input-container{
        width: 100%;
      }
    }
    &:nth-of-type(2){
      .text-input-container{
        textarea{
          height: 100px;
        }
      }
    }
    &:nth-of-type(3) {
      z-index: 4;
      .dropdown-input-container{
        justify-content: start;
        &:nth-of-type(2){
          z-index: -1;
          .dropdown-input-wrapper{
            width: 100% !important;
            z-index: -2;
          } 
        }
        &:nth-of-type(3){
          z-index: -3;
        }
      }
    }
    &:nth-of-type(4) {
      z-index: 3;
    }
    &:nth-of-type(5) {
      z-index: 2;
      .dropdown-input-container{
          &:nth-of-type(1){
            .dropdown-input-wrapper{
              .dropdown-selector-container{
                .dropdown-container{
                  .dropdown-body-container{
                    max-height: 200px;
                    overflow-x: hidden;
                  }
                }
              }
            }
          }
          &:nth-of-type(2){
            .dropdown-input-wrapper{
              .dropdown-selector-container{
                .dropdown-container{
                  .dropdown-body-container{
                    max-height: 200px;
                    overflow-x: hidden;
                  }
                }
              }
            }
          }
      }
      .text-input-container{
        z-index: -1;
        min-width: 100px;
        max-width: 190px;
      }
    }
    &:nth-of-type(6) {
      z-index: 1;
    }
    &:nth-of-type(7) {
      z-index: 0;
    }
    .text-input-container {
      flex: 1;
      min-width: 0;
      .label-container{
        p{
          color: ${getColor('gray1')};
          font-family: "GilroySemiBold";
        }
      }
      input {
        height: 40px;
        color: ${getColor('brandDarkHight')};
        font-family: 'GilroySemiBold';
        flex: 1;
      }
    }
    
    .dropdown-input-container {
      height: 40px;
      flex: 1;
      position: relative;
      min-width: 150px;
      .default{
        border: none;
        &.active{
          border: none !important;
        }
        .dropdown-container{
          .dropdown-body-container{
            display: inline-table;
            overflow: hidden !important;
            &.expanded{
              display: inline-flex;
              transform: translateY(-15px) !important;
              z-index: -10;
              .options-container{
                padding-top: 12px;
                padding-left: 12px;
                max-height: 235px !important;
                .role-container{
                  border-bottom: 0px;
                  border: 0px;
                  label{
                    p{
                      font-size: 14px;
                      padding-left: 6px;
                      max-width: 290px;
                    }
                  }
                }
                button{
                  :last-of-type{
                    display: none;
                  }
                }
              }
            }
            .options-containter{
              overflow-y: hidden !important;
              .role-container{
                display: none !important;
              }
              button{
                :last-of-type{
                display: none;
                }
              }
            }
          }
          .trigger-container{
            position: relative;
            justify-content: start;
            .arrow-container{
              position: absolute;
              right: 15px;
            }
            .label-container{
              max-width: 151px;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .input-label-container {
        z-index: 2;
        position: absolute;
        background: ${getColor('white')};
        top: -6px;
        left: 12px;;
        padding: 0 4px;
        p {
          font-weight: 400;
          font-size: 11px;
          font-family: 'GilroySemiBold';
          color: ${getColor('gray1')}
        }
      }
      .dropdown-selector-container {
        width: 100%;
        .dropdown-container {
          justify-content: flex-start;
          .trigger-container {
            width: 100%;
            background: ${getColor('white')};
            z-index:1 ;
            padding: 0 12px;
            height: 100%;
            border: 1px solid ${getColor('border')};
            border-radius: 16px;
            .label-container {
              flex: 1;
              justify-content: flex-start;
              overflow: hidden;
              color: ${getColor('brandDarkHight')};
              p {
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: ${getColor('brandDarkHight')};
                font-family: "GilroySemiBold";
              }
            }
        
          }
          .dropdown-body-container {
            background: ${getColor('white')};
            width: fit-content;
            max-height: 235px;
            min-width: 186px;
            overflow: auto;
            align-items: flex-start;
            .options-container {
              padding: 24px;
              padding-bottom: 8px;
              flex-direction: column;
              align-items: stretch;
              flex: 1;
              .role-container {
                border-bottom: 1px solid ${getColor('border')};
                padding: 16px 0;
                justify-content: flex-start;          
                &:nth-last-of-type(1) {
                  border: none;
                }
                p{
                  color: ${getColor('brandDarkHight')};
                  font-family: 'GilroySemiBold';
                  overflow-x: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  max-width: 110px;

                }
              }
            }
            border: 1px solid ${getColor('border')};
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            &.expanded {
              transform: translateY(-24px);
              z-index: -3;
            }
            &.collapsed {
              transform: translateY(-40px) scaleY(.8);
              transform-origin: top;
            }
          }
        }
      }
    }
  }
  
  .actions-section-container {
    flex-direction: column;
    width: 100%;
    gap: 16px;
    align-items: flex-start;
    .action-buttons-container {
      gap: 16px;
      width: 100%;
      justify-content: flex-start;
      padding: 16px 0px;
      button {
        flex: 1;
        height: 56px;
        border-radius: 30px;
        transition: all ease-in-out .3s;
        background: transparent;
        justify-content: center;
        :nth-of-type(1) {
          border: 1px solid ${getColor('brandDark')};
          p {
            color: ${getColor('brandDark')};
          }
        }
        &.delete {
          flex: 0.5;
          border: 1px solid ${getColor('error')};
          background: ${getColor('error')};
          p {
            color: ${getColor('white')};
          }
        }
        :nth-last-of-type(1) {
          border: 1px solid ${getColor('brand')};
          background: ${getColor('brand')};
          p {
            color: ${getColor('white')};
          }
        }
      }
    }
  }
  position: relative;
  .report-list-container {
    display: inline-block;
    flex:1;
    overflow-y: auto;
    max-height: 50vh;
    .report-container {
      > * {
        flex: 2;
      }
      &:nth-of-type(1) {
        border-top: 1px solid ${getColor('border')};
      }
      width: 100%;
      position: relative;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid ${getColor('border')};
      button{
        justify-content: start;
        padding: 0;
        .tooltip-trigger-container{
          position: absolute;
          .tooltip-container{
            bottom: 25px;
            p{
              white-space: normal;
              text-overflow: initial;
            }
          }
        }
      }
      p{
        &:nth-of-type(1){
          max-width: 100px;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:nth-of-type(2){
          text-align: center;
        }
      }
    }
    .status-container, .report-actions-container {
      flex: 1;
    }
    .report-actions-container {
      gap: 16px;
    }
    .indicator-container {
      width: 8px;
      height: 8px; 
      border-radius: 4px;
      margin-right: 4px;
      &.active {
        background: ${getColor('success')}
      }
      &.inactive {
        background: ${getColor('paused')}
      }
    }
    .report-action-button-container {
      color: ${getColor('brandDark')};
      font-size: 24px;
      :nth-of-type(2) {
        font-size: 16px;
      }
    }
  }

  @media only screen and ${devices.tablet}{
    max-height: auto;
    overflow-y: visible;
    .row-container {
      &:nth-of-type(1){
        flex-direction: row;
        .text-input-container{
          width: auto;
        }
      }
    }
    .actions-section-container {
      .action-buttons-container {
        padding: 16px 40px;
      }
    }
    .report-list-container{
      width: 100%;
    }
  }

  @media only screen and ${devices.xs}{
    .report-list-container{
      .report-container{
        p{
          &:nth-of-type(1){
            max-width: 200px;
          }
        }
      }
    }
  }
`

export default StyledWorkbookExport;