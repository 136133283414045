import { devices } from 'utils/constants/media-queries'
import styled from 'styled-components'
import Container from '../../shared/containers/container'

export const StyledSnackManager = styled(Container)`
  flex-direction: column;
  position: absolute;
  gap: 4px;
  width: 80%;
  max-width: 500px;
  top: 72px;
  right: 0;
  align-items: flex-start;
  transition: ease-in-out .3s all;
  z-index: 5;
  @media only screen and ${devices.tablet}{
    display: block;
    bottom: 8px;
    left: 8px;
    top: auto;
  }
  @media only screen and ${devices.laptop}{
    display: block;
  }
`