import React from 'react'
import { LegacyToolTip, Tooltip } from 'components'
import StyledButton from './button-styled'
import { ButtonProps } from './interfaces'

export const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    color,
    isFetching,
    tooltipLabel,
    disabled,
    tooltip,
    className,
  } = props
  return (
    <StyledButton color={color} disabled={isFetching || disabled} className={`${className || ''}${isFetching ? 'fetching' : ''}`} onClick={onClick &&((e) => onClick(e))}>
      {
        tooltipLabel ? (
          <LegacyToolTip label={tooltipLabel}>
            { children }
          </LegacyToolTip>
        ) : (
          <>
            { children }
          </>
        )
      }
      {tooltip && <Tooltip label={tooltip.label} position={tooltip.position} type={tooltip.type}/> }
    </StyledButton>
  )
}
export default Button