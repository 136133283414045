import { useEffect, useState } from 'react'
import { IFilterValue } from '../../../../utils/constants/interfaces'
import { Container } from '../../containers'

export const SelectionSection = ({values,handleOptions,options, sortBy}: {values:IFilterValue[], handleOptions: (newValues:{label:string,id:string}[])=>void, options:{label:string,id:string}[], sortBy: string})=>{
    const getSelectedValues = () =>{
        let selectedValues = values.filter((v:IFilterValue)=>v.isSelected)
        return selectedValues
    }
    let label = `Show only selected (${getSelectedValues().length})`
    const [ sectionLabel, setSectionLabel ] = useState(label)
    const [ isSectionClicked, setIsSectionClicked ] = useState(false)

    useEffect(()=>{
        setSectionLabel(`Show only selected (${getSelectedValues().length})`)
        if(!values.find((val)=>val.isSelected)){
          handleOptions(sortOptions(options.map((o:{id:string, label:string})=>({value:o.label,id:o.id,isSelected:true}))).map((o:IFilterValue)=>({label:o.value,id:o.id})))
        }
    },[values])

    const sortOptions = (options:IFilterValue[])=>{
      if(sortBy === 'ASC'){
        return [...options].sort((a:{value:string,id:string},b:{value:string,id:string})=>{
          if ( a.value.toLowerCase() > b.value.toLowerCase() ){
              return 1;
          }
          if ( a.value.toLowerCase() < b.value.toLowerCase() ){
          return -1;
          }
          return 0;
        })
      }else{
        return [...options].sort((a:{value:string,id:string},b:{value:string,id:string})=>{
          if ( a.value.toLowerCase() < b.value.toLowerCase() ){
              return 1;
              }
          if ( a.value.toLowerCase() > b.value.toLowerCase() ){
            return -1;
          }
              return 0;
        })
      }
    }

    const handleClick = (chunkSelected:IFilterValue[])=>{
      if(chunkSelected.length && !isSectionClicked){
        setIsSectionClicked(true)
        const orderedChunkSelected = sortOptions(chunkSelected)
        handleOptions(orderedChunkSelected.map((cs:IFilterValue)=>({label:cs.value,id:cs.id})))
        setSectionLabel(`Show all`)
      }else{
        handleOptions(sortOptions(options.map((o:{id:string, label:string})=>({value:o.label,id:o.id,isSelected:true}))).map((o:IFilterValue)=>({label:o.value,id:o.id})))
        setIsSectionClicked(false)
        setSectionLabel(label)
      }
    }

    const renderSection = ()=>{
      const chunkSelected = getSelectedValues()
      return (<p onClick={()=>chunkSelected.length !== 0 && handleClick(chunkSelected)} className={`${chunkSelected.length > 0 ? 'active': 'inactive'}`}>{sectionLabel}</p>)
    }
    
    return (<Container className={'selected-option-label'}>
       {renderSection()}
    </Container>)
}

export default SelectionSection