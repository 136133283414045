const sendNowTitle = "Send now"
const myFiltersTitle = "My Filters"
const saveFilterTitle = "Save Filter"
const downloadTitle = "Download"
const scheduledSendTitle = "Scheduled Send"
const filterWarningTitle = "Filter cannot be deleted"
const ConfirmationTitle = "Confirmation"
const premiumDownloadTitle = "Exports"
export {
    sendNowTitle,
    myFiltersTitle,
    saveFilterTitle,
    downloadTitle,
    scheduledSendTitle,
    filterWarningTitle,
    ConfirmationTitle,
    premiumDownloadTitle
}