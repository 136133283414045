import { Button, Container, Modal, Text } from '..'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

interface ConfirmInputProps {
  data: {
    message:string
    callback:() => void
  }
  onRequestClose: () => void
}

const StyledConfirmInput = styled(Container)`

.modal-content-container{
    flex-direction: column;
    gap: 16px;
    white-space: pre-line;
    .buttons-container {
      justify-content: space-between;
      max-width: 280px;
      width: 100vw;
      gap: 32px;
      button {
        padding: 4px ;
        max-width: 280px;
        width: 100%;
      }   
    }
  }

`

export const ConfirmInput = ({ data: { message, callback }, onRequestClose }: ConfirmInputProps) => {
  const [confirmationMessage, setconfirmationMessage] = useState(message)
  
  const onConfirm = () => {
    callback()
    onRequestClose()
  }

  useEffect(() => {
    if (!message) {
      setTimeout(() => {
        setconfirmationMessage(message)
      }, 300);
    } else {
      setconfirmationMessage(message)
    }
  }, [message])
  

  return (
    <StyledConfirmInput className={`confirm-input`}>
      <Modal isOpen={!!message} requestClose={onRequestClose}>
        <Text textStyle='h3'>Confirm</Text>
        <Text>{confirmationMessage}</Text>
        <Text> </Text>
        <Container expand className="buttons">
          <Button onClick={onRequestClose} color='white'>
            <Text textStyle='button' color='brand'>No</Text>
          </Button>
          <Button onClick={onConfirm}>
            <Text textStyle='button' color='white'>Yes</Text>
          </Button>
        </Container>
      </Modal>
    </StyledConfirmInput>
  )
}

export default ConfirmInput