import styled from "styled-components";
import { Container } from "components"
import { getColor } from "../../../../utils";

const StyledRangeOptions = styled(Container)`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    &.expanded-container{
        
    }
    &.colapsed-container{
        display: none;
    }
    .selected-option-container{
        border: 1px solid rgb(215, 212, 225);
        padding: 4px 8px;
        border-radius: 20px;
        width: 190px;
        height: 30px;
        justify-content: space-between;
        &.bordered{
            border: 1px solid ${getColor('brandDark')};
        }
        p.h4{
            width: 100%;
            text-align: start;
            font-weight: 400 !important;
        }
        button.x-icon{
            display: flex;
            justify-content: flex-end;
            padding-right: 0;
        }

    }
    .dropdown-container{
        width: 100%;
        .dropdown-body-container{
            width: inherit;
            position: relative !important;
        }
    }
    .options-container{
        width: inherit;
        flex-direction: column;
        position: absolute;
        top: calc(100% + 0px);
        transition: ease-in-out all .3s;
        background-color: white;
        z-index: 3;
        border: 1px solid var(--colors-border);
        &.expanded{
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
        &.colapsed{
            visibility: hidden;
            opacity: 0;
            transform: translateY(-3px);
        }
        .option-container{
            width: inherit;
            justify-content: flex-start;
            padding: 6px 8px 6px 0px;
            &.selected{
                background-color: rgb(235, 232, 248);
                p{
                    color: ${getColor('brandDark')};
                }
            }
            p{
                display: inline-block;
                color: #201947;
                padding-left: 9px;
            }
            :hover{
                background-color: #efedf5;
            }
        }
    }
`

export default StyledRangeOptions