import { mainSelectors, hooks, dataTrackingOperations } from 'state'
import StyledError from './error-styled'
import { Issue } from '../../../utils/constants/interfaces'
import { Container, Text, Button } from 'components'
import { Link } from 'components/shared'
import { getParams } from 'utils';
import { emailUsLabel, IssuesCodes, suggestionLabel, tryAgainLabel } from 'utils/constants'
import { ErrorBackground } from '../../../assets'

const Error = () =>{
    const { useAppSelector, useAppDispatch } = hooks
    const dispatch = useAppDispatch()
    const { selectmain } = mainSelectors
    const { healthStatus } = useAppSelector(selectmain)
    const { trackEvent } = dataTrackingOperations


    const onTryAgain = () => {
        dispatch(trackEvent({
          event: 'user_select_retry',
          values: JSON.stringify({ redirectUrl: window.location.href }),
          eventCategory: 'error_page',
        }))
        dispatch(trackEvent({
          event: 'switch_on_error_user_retry',
          values: JSON.stringify({ redirectUrl: window.location.href }),
          eventCategory: 'switch',
        }))
        window.location.assign(getParams().redirectUrl)
      }

    const onSendEmail = () => {
        dispatch(trackEvent({
          event: 'user_select_send_error_email',
          values: JSON.stringify({ redirectUrl: window.location.href }),
          eventCategory: 'error_page',
        }))
      }

    const renderHealthStatus = () => {
        if(healthStatus){
            const { issues } = healthStatus
            let foundIssue = Object.keys(IssuesCodes).find((iC: string)=>{
                return issues.find((issue: Issue)=>{
                    return issue.userHealthIssueCode === iC
                })
            })
            if(!foundIssue){
                return null
            }
            return (foundIssue && <Text textStyle='h3'>
                {IssuesCodes[foundIssue]}
            </Text>)
        }
    }


    return(
        <StyledError className="Error">
            <Container className="title-section">
                <Container>
                    <img alt="Error" src={ErrorBackground} />
                </Container>
                {renderHealthStatus()!}
                <Text textStyle='h3'>{suggestionLabel}</Text>   
            </Container>
            <Container className="actions-section">
                <Container>
                 <Button onClick={onTryAgain}> <Text>{tryAgainLabel}</Text> </Button>
                </Container>
                <Text>
                or
                </Text>
                <Container onClick={onSendEmail}>
                <Link to={`mailto:tech-support@pistildata.com?subject=Issue%20Report&body=Issue%20description.`}>
                    <Text>
                        {emailUsLabel}
                    </Text>
                </Link>
                </Container>
                
            </Container>
        </StyledError>
    )
}

export default Error