import { ReactNode } from 'react'
import styled from 'styled-components'
import { Container } from '../containers'
import { getColor } from 'utils'
import { devices } from '../../../utils/constants/media-queries'

interface ModalProps {
  isOpen: boolean
  requestClose: () => void
  children: ReactNode
  position: string | '';
}

interface IStyledModal{
  position: string,
  className: string
}

const StyledModal = styled(Container)<IStyledModal>`
  position: fixed;
  top: 72px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transition: 0.3s ease-in-out all;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  .modal-overlay-container {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content-container {
    align-items: baseline;
    position: ${({position}:IStyledModal)=> position === 'top' ? 'absolute': 'relative'};
    top: ${({position}:IStyledModal)=> position === 'top' ? '70px' : ''};
    padding: 32px;
    border-radius: 0;
    background-color: ${getColor('white')};
  }

  @media only screen and ${devices.tablet}{
    top: 0;
    .modal-content-container {
      border-radius: 32px;
    }

    .modal-overlay-container {
      display: block;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  @media only screen and ${devices['2xl']}{
    .modal-content-container{
      top: ${({position}:IStyledModal)=> position === 'top' ? '100px' : ''};
    }
  }
`

export const Modal = (props: ModalProps) => {
  const { isOpen, children, requestClose, position} = props
  return (
    <StyledModal position={position} className={`${isOpen ? 'modal' : 'hidden modal'}`}>
      <Container className="modal-overlay" onClick={() => requestClose()} />
      <Container className="modal-content">{children}</Container>
    </StyledModal>
  )
}

export default Modal
