import React from 'react'
import styled from 'styled-components'

import { getColor } from 'utils'
import { Container, Text } from '..'
interface RadioInputProps {
  label: string
  inputName: string
  selectedRadioButton: string
  setSelectedRadioButton: any
  noVisibleLabel?: boolean
}

const StyledRadioInput = styled(Container)`
  .wrapper-container {
    border-bottom: 1px solid ${getColor('brandDark')};
  }
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: all 250ms linear;
`

const StyledInput = styled.input`
  display: none;
`

const StyledRadioButton = styled(Container)`
  transition: background-color 250ms ease-in-out;

  height: 20px;
  min-width: 20px;
  border: 2px solid ${getColor('brandDark')};
  border-radius: 50%;
  padding: 2px;

  &.selected-container {
    border: 2px solid ${getColor('brand')};
    background: ${getColor('white')};
  }
`

const RadioButtonCenter = styled(Container)`
  transition: background-color 250ms ease-in-out;

  &.radio-button-center-container {
    height: 85%;
    width: 85%;
    border-radius: 50%;
    background: ${getColor('brand')};
  }
`

export const RadioInput = (props: RadioInputProps) => {
  const { label, inputName, selectedRadioButton, setSelectedRadioButton, noVisibleLabel } =
    props

  const isRadioSelected = (value: string): boolean =>
    selectedRadioButton === value

  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadioButton(e.currentTarget.value)
  }

  const handleLabelClick = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    setSelectedRadioButton(e.currentTarget.outerText)
  }

  return (
    <StyledRadioInput className="select" expand>
      <Container className={`${noVisibleLabel ? 'wrapper-no-label' : 'wrapper'}`} expand>
        <StyledInput
          type="radio"
          name={inputName}
          value={label}
          className="input-radio"
          checked={isRadioSelected(label)}
          onChange={handleRadioClick}
        />
        <StyledLabel onClick={handleLabelClick}>
          {
            !noVisibleLabel && <Text>{label}</Text>
          }
          <StyledRadioButton
            className={`radio-button ${
              isRadioSelected(label) ? 'selected' : 'unselected'
            }`}
          >
            <RadioButtonCenter
              className={`${
                isRadioSelected(label) ? 'radio-button-center' : ''
              }`}
            />
          </StyledRadioButton>
        </StyledLabel>
      </Container>
    </StyledRadioInput>
  )
}

export default RadioInput
