import { useEffect, useState } from 'react'

export default function useScreenSize() {
  const [screenWidth, setSreenWidth] = useState(window.outerWidth);
  const handleResize = () =>{
    setSreenWidth(window.innerWidth)
  }

  useEffect(()=>{
    window.addEventListener('resize', handleResize)
    return () => {window.removeEventListener('resize', handleResize)}
  })
  return [ screenWidth ] 
}
