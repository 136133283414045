import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={60}
    height={60}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30 0C13.5 0 0 13.5 0 30s13.5 30 30 30 30-13.5 30-30S46.5 0 30 0Zm11.1 36.9c1.2 1.2 1.2 3 0 4.2-1.2 1.2-3 1.2-4.2 0L30 34.2l-6.9 6.9c-1.2 1.2-3 1.2-4.2 0-1.2-1.2-1.2-3 0-4.2l6.9-6.9-6.9-6.9c-1.2-1.2-1.2-3 0-4.2 1.2-1.2 3-1.2 4.2 0l6.9 6.9 6.9-6.9c1.2-1.2 3-1.2 4.2 0 1.2 1.2 1.2 3 0 4.2L34.2 30l6.9 6.9Z"
      fill="#E31B0C"
    />
  </svg>
)

export default SvgComponent
