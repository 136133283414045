import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { getColor } from 'utils'
import { Text } from '.'
import { Container } from '..'
import { v4 as uuid } from 'uuid';
import { SortIcon } from './assets'

interface TableProps {
  rows?: unknown[], 
  isFetching?: boolean
  options?: {
    name: string
    label: string
    flex?: number,
    onlyOnParent?: boolean,
    onSort?: () => void
    sort?: () => void,
    order?: string,
    toggleSort?: () => void,
    layout: (value: any) => JSX.Element,
  }[]
  customOptions?: {
    headerLayout?: ReactNode
    getExpandedState?: (row:any) => boolean
    onToggleExpand?: (row:any) => void
    rowLayout?: (value: any) => JSX.Element,
  }
}

const StyledTable = styled(Container)`
  position: relative;
  flex-direction: column;
  align-items: stretch;
  .table-body-container {
    flex-direction: column;
    align-items: stretch;
    justify-content: start;
    overflow: auto;
    overflow-y: overlay;
    padding-bottom: 50px;

  }
  .row-container {
    z-index: 1;
  }
  .nested-rows-container {
    flex-direction: column;
  }
  .nested-row-container, .nested-rows-container {
    width: 100%
  }
  .cell-container {
  }
  .row-wrapper-container {
    transition: ease-in-out .3s all;
    justify-content: flex-start;
    padding: 4px 32px;
    background: ${getColor('white')};
    flex-direction: column;
    align-items: stretch;
    z-index: unset;

  }
  .header-row-wrapper-container {
    z-index: 2;
    border-radius: 24px 24px 0px 0px;
    flex-direction: column;
    background: ${getColor('clearOverlay')};
    align-items: stretch;
    padding:  12px 32px;
    .sort-icon-container {
      .fill {
        fill: ${getColor('brandDark')};
      }
      &.Asc {
        .up {
          fill: ${getColor('brand')};
        }
      }
      &.Desc {
        .down {
          fill: ${getColor('brand')};
        }
      }
    }
  }
  .row-wrapper-container:nth-of-type(2n) {
    background: ${getColor('clearOverlay')};
  }
  .row-container {
  }
  .nested-rows-container {
    padding:  12px 0;
  }
  .nested-row-container {
    padding:  8px 0;
  }

  .custom-row-container {
    transition: ease-in-out .3s all;
    
    transform-origin: top;
    &.expanded {
      max-height: 1000px;
      opacity: 1;
      transform: scaleY(1);
    }
    &.collapsed {
      max-height: 0;
      opacity: 0;
      transform: scaleY(0);
      padding: 0;
    }
  }
`

export const Table = ({rows, options, customOptions, isFetching}: TableProps) => {
  const [activeRow, setActiveRow] = useState()

  const renderRow = (row:unknown, rowIndex?:number) => {
    if (Array.isArray(row)) {
      return (
        <Container className="nested-rows">
          {
            row.map((nestedRow, rowIndex) => {
              return <Container key={nestedRow.id} className="nested-row">{renderRow(nestedRow, rowIndex)}</Container>
            })
          }
        </Container>
      )
    }

    if (options) {
      return options.map(({layout, onlyOnParent, flex }) => {
        return <Container flex={flex || 1} className="cell">
          {
            !onlyOnParent 
              ? layout(row)
              : (rowIndex === 0) && layout(row)
          }
        </Container>
      })
    }
  }

  const onClickRow = (row:any) => {
    customOptions && (customOptions as any).onToggleExpand(row)
    setActiveRow(row[0]?.id)
  }

  const renderTable = () => {
    return (
      <>
        <Container className="header-row-wrapper">
          {options?.length && <Container className="header row">
            {options.map(({label, flex, onSort, order}) => {
              return (
                <Container onClick={onSort} key={label} flex={flex || 1} className="cell">
                  <Text textStyle='s2'>{label}</Text>
                  {
                    onSort && (
                      <Container className={`${order} sort-icon`}>
                        <SortIcon />
                      </Container>
                    )
                  }
                </Container>
              )
            })}
          </Container>}
            {customOptions && customOptions.headerLayout && <Container className="cell">{customOptions.headerLayout}</Container>}
        </Container>
        <Container className="table-body">
          {
            isFetching 
            ? <Container expand ><Text>Loading...</Text></Container>
            : rows?.map((row:any) => {
            return (
              <Container key={row[0] && row[0].id} className={`${row[0] && activeRow === row[0]?.id ? 'active ': ''}row-wrapper`} onClick={() => onClickRow(row)}>
                {options?.length && <Container className="row">{renderRow(row)}</Container> }
                {
                  customOptions 
                  && customOptions.getExpandedState
                  && customOptions.rowLayout
                  && (
                    <Container
                      className={`${customOptions.getExpandedState(row) ? 'expanded' : 'collapsed' } custom-row`}
                    >
                      {customOptions.rowLayout(row)}
                    </Container>
                  )
                }
              </Container>
              )
            })
          }
        </Container>
      </>
    )

  }

  return <StyledTable expand className="table">{renderTable()}</StyledTable>
}

export default Table