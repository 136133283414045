const toPlaceholder = "Use a comma to separate emails"
const subjectPlaceholder = "Pistil Document"
const emailBodyPlaceholder = "Hello, you have received a document."
const setDefaultEmailBodyContent = (workbookName: string) => `Here is your requested export(s) for ${workbookName}.`

export {
    toPlaceholder,
    subjectPlaceholder,
    emailBodyPlaceholder,
    setDefaultEmailBodyContent
}
