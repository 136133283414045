import styled from "styled-components";
import { getColor } from "../../../../utils";
import { Container } from "../../containers";

const  StyledDropdownMenu = styled(Container)`
  &.disabled{
    opacity: 0.5;
    pointer-events: none;
  }
  position: relative;
  cursor:pointer;
  .dropdown-body-container  {
    border-radius: 16px;
    border: 1px solid ${getColor('brand')};
    overflow: hidden;
    background: ${getColor('white')};
  }
  .options-container {
    z-index: 2;
    max-height: calc(50vh - 72px);
    overflow: auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    .role-container {
      padding: 4px 16px;
      justify-content: flex-start;
      align-items: baseline;
      gap: 10px;
      &.option-selected-container{
        background: rgba(0,0,0,.05);
      }
      &.tier-element-text-container{
        p{
          font-size: 16px;
          font-weight: 500;
        }
      }
      &:hover {
        background: rgba(0,0,0,.05);
      }
      p {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
      }
    }
    button {
      width: 75px;
      align-self: flex-end;
      margin-bottom: 8px;
      margin-right: 8px;
    }
    .message-container {
      max-width: 150px;
      align-self: center;
      text-align: center;
      p {
        font-size: 13px!important;
        line-height: 14px;
        padding-bottom: 8px;
      }
    }
  }
  .dropdown-body-container {
    z-index: 1;
  }
  .trigger-container {
    padding: 4px 16px;
    .label-container {
      align-items: baseline;
      gap: 10px;
      &.tier-group-label-container{
        p{
          color: #A2B0EE !important;
        }
      }
    }
    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .checkbox-container {
    label {
      padding: 4px;
      display: flex;
      width: 100%;
      &:hover {
        background: ${getColor('brandClear')};
      }
    }
  }
`

export default StyledDropdownMenu