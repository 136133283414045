import React from 'react'
import styled from 'styled-components'
import { ColorType, getColor } from '../../../utils'
import { Container } from '../'
import { TextInput } from '.'
import { Image } from '../ui-elements'
import { SearchIcon, ErrorIcon } from 'assets'
import { MdClear, MdSearch } from 'react-icons/md'

interface SearchInputProps {
  placeholder?: string,
  value?: string,
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void,
  color?: ColorType,
  background?: ColorType
  onClear?: () => void
}

const StyledInput = styled(Container)`
    position: relative;
    .search-icon-container {
      position: absolute;
      left: 8px;
      cursor: pointer;
    }
    input {
      padding-left: 32px;
      width: 100%;
      border: 1px solid ${getColor('border')};
    }
`

export const SearchInput = (props: SearchInputProps) => {
  return (
    <StyledInput className={"search-input"}>
      <Container className={'search-icon'} onClick={props.onClear}>
        {
          !props.value || props.value === '' ? 
            (<MdSearch/>)
          :
          (<MdClear/>)
        }
        
      </Container>
      <input type={'text'} {...props} />
    </StyledInput>
  )
}
export default SearchInput