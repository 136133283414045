import * as React from "react";
import { SVGProps } from "react"

const SVGComponent = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            fill="currentcolor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="css-16v7ah"
            color="$placeholder"
            {...props}
        >
            <title />
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-9-2v8h-2v-8h2zm0-2V6h-2v2h2z"
            fill="currentColor"
            />
        </svg>
    );
}
export default SVGComponent;