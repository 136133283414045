import styled, { css } from 'styled-components'
import { ColorType, getColor } from '../../../utils'

interface textProps {
  children: string
  textStyle?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'button' | 's1' | 's2' | 'support'
  color?: ColorType
  textAlign?: 'left' | 'center' | 'right'
}

const StyledText = styled.p`
  ${({color}: textProps) => css`color: ${getColor(color || 'text')};`};
  ${({textAlign}: textProps) => css`text-align: ${textAlign || 'text'};`};
  margin: 0px;
  padding: 0px;
  &.h1 {
    font-family: "GilroyBlack";
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.4000000059604645px;
  }
  &.h2 {  
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.4000000059604645px;
  }
  &.h3 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0px;
  }
  &.h4 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  &.h5 {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
  }
  &.h6 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.15000000596046448px;
  }
  &.h7 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  &.button {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.46000000834465027px;
  }
  &.s1 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  &.s2 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  &.support {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.4000000059604645px;
  }
`

export const Text = ({children, textStyle="h7", color, textAlign}: textProps) => {
  return (
    <StyledText color={color} className={textStyle} textAlign={textAlign}>{children}</StyledText>
  )
}
export default Text