import { IFilter, IFilterValue } from "utils/constants/interfaces"
import StyledDropdown from "./dropdown-styled"
import { Text, DropdownInputv2 } from "components"

const Dropdown = ({values, setValues}: {values: IFilter, setValues: React.Dispatch<React.SetStateAction<IFilter>>}) => {
    const updateSelectedOptions = (id: string)=>{
        let newFilterValues = values.filterValues.map((v:IFilterValue)=>{
            if(v.id === id){
                return {...v, isSelected: !v.isSelected}
            }else if(v.isSelected){
                return {...v, isSelected: !v.isSelected}
            }
             return v
        })
        if(newFilterValues.length > 0){
            setValues((prevValues: IFilter)=>({...prevValues,  filterValues: newFilterValues}))
        }
    }
    const handleOnChange = (filterValue: IFilterValue[], sortBy: string)=>{
        updateSelectedOptions(filterValue[0].id)
    }

    const getFilterLabelSelected = ()=>{
        const selectedFilters = values?.filterValues?.filter((filterValue:IFilterValue)=>{
            return filterValue.isSelected
        })
        if(selectedFilters && selectedFilters.length > 0){
            return selectedFilters
        }
        return []
    }
    const restoreSelectedValues = () => {

    }

    const getFilterValues = (filterValues:IFilterValue[]) =>{    
        return filterValues.map((filterValue:IFilterValue)=>{
            return({
                label: filterValue.value,
                id: filterValue.id
            })
        })
    }
    const filterSelectedValues = getFilterLabelSelected()
    const filterValues = getFilterValues(values.filterValues)
    return(<StyledDropdown className={"dropdown"}>
        <Text textStyle="h4">State</Text>
        <DropdownInputv2 
            selectedValues={filterSelectedValues} 
            allowMultipleSelection={values.allowMultipleSelection}
            options={filterValues} 
            onChange={handleOnChange}
            filterSettingGuid={values.filterSettingGuid}
            resetFilteredValues={restoreSelectedValues}
            sortBy={values.order}
            filterName={values.filterDisplayName}
            showClearButton={values.showClearButton}
            isValueRequired={values.isRequired}
        />
    </StyledDropdown>)
}

export default Dropdown