import { useCookies } from 'react-cookie';
import { cookifyObject, cookifyString, getUserFromSubcookies, getSubCookieNames, getUserCookieParametersConfig } from 'utils/tools';
import { dataTrackingOperations, hooks, mainSelectors } from './state';
import { useEffect, useMemo, useState } from 'react';
import Dashboard from './pages/dashboard';

let inactivityTimeOut : any
let timeCounter = 0
const ParentComponent = () =>{
    const { trackEvent } = dataTrackingOperations
    const { useAppDispatch, useAppSelector } = hooks
    const { selectmain } = mainSelectors
    const { lastActivityTime } = useAppSelector(selectmain)
    const userCookieNames = getSubCookieNames();
    const userDataFromCookie = getUserFromSubcookies();
    const userCookieConfig = getUserCookieParametersConfig();
    const [, setCookie] = useCookies(Object.values(userCookieNames))
    const dispatch  = useAppDispatch() 
    const setUserCookie = async (cookieLabelName: string, cookieBase64StringValue: string) => {
      setCookie(cookieLabelName, cookieBase64StringValue, userCookieConfig);
    }
  
    const trackEventSwitchOnSessionTimeout = async () => {
      dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ targetApp: 'PAM' }), event: 'switch_on_session_timeout' }))
      window.location.assign(`${process.env.REACT_APP_LANDING_URL}/time-out`)
    }
  
    useEffect(()=>{
      inactivityTimeOut = setInterval(() => {
        if(timeCounter >= (60 * 60 * 4)){
          trackEventSwitchOnSessionTimeout()
        }
        ++timeCounter
      }, 1000);
      return ()=>{
        clearTimeout(inactivityTimeOut)
      }
    },[])
  
    useEffect(() => {
      const resetInactivityPeriod = () => {
        if (timeCounter) {
          clearTimeout(inactivityTimeOut)
        }
        timeCounter = 0
  
        const newUser = {
          ...userDataFromCookie,
          cookie_expiration_date: new Date().getTime() + (60000 * 60 * 4)
        }
  
        if (Object.keys(userDataFromCookie).length) {
          const { id_token, access_token, refresh_token, ...userData } = newUser;
  
          setUserCookie(userCookieNames.userData, cookifyObject(userData));
          setUserCookie(userCookieNames.accessTokenLabel, cookifyString(access_token));
          setUserCookie(userCookieNames.refreshTokenLabel, cookifyString(refresh_token));
          setUserCookie(userCookieNames.idTokenLabel, cookifyString(id_token));
        }
  
  
      }
      resetInactivityPeriod()
    }, [lastActivityTime])
  
  
    const children = useMemo(()=>(<Dashboard/>),[])
    
    return (<>{children}</>)
  }

  export default ParentComponent