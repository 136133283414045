import styled from 'styled-components'
import { getColor } from 'utils'
import { Container } from 'components'

const StyledWorkbookFilterSave = styled(Container)`
  width: 100%;
  flex-direction: column;
  .control-list-container {
    width: 100%;
    padding: 0 8px;
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-items: flex-start;
    gap: 16px;
    box-sizing: border-box;
    max-height: 0;
    transition: ease-in-out all .3s;
    &.visible {
      padding: 32px;
      max-height: calc(100vh - 580px);
    } 
    .control-list-element-container {
      border: 1px solid ${getColor('brandClear')};
      border-radius: 12px;
      width: 100%;
      padding: 8px;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      .label-container{
        position: absolute; 
        background: ${getColor('white')};
        top: -9px;
        p {
          line-height: 16px;
          font-weight: 400;
        }
      }
      .control-values-container {
        gap: 4px;
        flex-wrap: wrap;
        justify-content: flex-start;
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid ${getColor('border')};
          padding: 4px 8px;
          border-radius: 24px;
          height: 32px;
          background: ${getColor('brand')}1d;
          line-height: 16px;
          font-weight: 400;
          font-size: 14px;
          color: ${getColor('brandDark')};
        }
      }
    }
  }

  .filter-name-input-container {
    padding: 24px 40px;
    width: 100%;
    .text-input-container {
      width: 100%;
      input {
        height: 40px;
        width: 100%;
      }
    }
  }

  .actions-section-container {
    flex-direction: column;
    width: 100%;
    gap: 16px;
    align-items: flex-start;
    .action-buttons-container {
      gap: 16px;
      width: 100%;
      justify-content: flex-start;
      padding: 16px 40px;
      padding-bottom: 40px;
      button {
        flex: 1;
        height: 56px;
        border-radius: 30px;
        transition: all ease-in-out .3s;
        background: transparent;
        justify-content: center;
        :nth-of-type(1) {
          border: 1px solid ${getColor('error')};
          p {
            color: ${getColor('error')};
          }
        }
        :nth-last-of-type(1) {
          border: 1px solid ${getColor('brand')};
          background: ${getColor('brand')};
          p {
            color: ${getColor('white')};
          }
        }
      }
    }
    .checkbox {
      padding: 40px;
      padding-top: 16px;
      border-bottom: 1px solid ${getColor('border')};
      width: 100%;
      p {
        font-size: 16px;
        letter-spacing: 0.15px;
        font-weight: 500;
      }
    }
  }
  .filter-details-trigger-container {
    width: 100%;
    cursor: pointer;
    padding: 0 40px;
    padding-bottom: 16px;
    justify-content: space-between;
    svg.visible {
      transform: rotate(180deg);
    }
  }
  .error-message-container {
    padding: 0 32px;
    max-width: 450px;
    text-align: center;
    height: 40px;
  }
  .msg-placeholder-container {
    height: 40px;
  }
  .modal-content-container {
    width: calc(100% - 32px);
    padding: 0;
    max-width: 660px;
  }
`

export default StyledWorkbookFilterSave;