import { AiOutlineSend, AiOutlineUnorderedList, AiOutlineDownload, AiFillHome } from "react-icons/ai";
  
  export const tabs = [
    {
      to: '/home',
      value: 'home',
      label: 'Home',
      icon: <AiFillHome/>
    },
    {
      to: '/filters',
      value: 'filters',
      label: 'Filters',
      icon: <AiOutlineUnorderedList />,
    },
    {
      to: '/send',
      value: 'send',
      label: 'Send',
      icon: <AiOutlineSend/>,
    },
    {
      to: '/download',
      value: 'download',
      label: 'Download',
      icon: <AiOutlineDownload/>,
    },
  ]; 