import { SVGProps } from "react"

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentcolor"
        className="css-1l9gz4q"
        viewBox="0 0 24 24"
        {...props}
    >
        <title />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M20 4H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zM4 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H4z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            d="M6 2h2v2H6zm1 9h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2zm1-13h2v2h-2zM2 7h20v1H2z"
        />
    </svg>
)
export default CalendarIcon

