import styled from "styled-components"
import { Container, TextInput } from "components"

const StyledDateInput = styled(Container)`
    width: 100%;
    margin-top:8px;
    .text-input-container input{
        width: 50px;
        height: 32px;
        border-radius: 0%;
        padding: 6px 8px;
        font-family: 'GilroyRegular';
    }
    .text-input-container{
        margin: 0 10px;
    }
    .range-input{
        .selected-option-container{
            width: 100px;
        }
    }
`

interface IDateInput {
    value: string,
    onChange: (e:React.ChangeEvent<HTMLInputElement>) => void,
    children: React.ReactNode,
}
const DateInput = (props: IDateInput) =>{
    const { value, onChange, children } = props
    return <StyledDateInput className='date-input'>
        <TextInput type="text" value={value} onChange={onChange}/>
        {children}
    </StyledDateInput>
}

export default DateInput