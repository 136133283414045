import { Container, Image, } from "../../components"
import styled from "styled-components"
import { getColor } from "../../utils"
import { PistilDataName } from "assets"
import { devices } from 'utils/constants/media-queries'


const StyledFooter = styled(Container)`
  display: none;
  justify-content: space-between;
  background: ${getColor('brandDark')};
  width: 100%;
  height: 4px;
  padding: 0 24px;
  white-space: nowrap;
  gap: 24px;
  @media only screen and ${devices.tablet}{
    display: block;
  }
  @media only screen and ${devices.laptop}{
  }
`

export const Footer = () => {
  return (
    <StyledFooter className={'footer'}>
    </StyledFooter>
  )
}
export default Footer