export const screenSizes ={
  xs: 320,
  sm: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
}

const breakpoints = {
    xs: `${screenSizes.xs}px`,
    sm: `${screenSizes.sm}px`,
    tablet: `${screenSizes.tablet}px`,
    laptop: `${screenSizes.laptop}px`,
    desktop: `${screenSizes.desktop}px`,
    "2xl": "1536px",
  }

  export const devices = {
    xs: `(min-width: ${breakpoints.xs})`,
    sm: `(min-width: ${breakpoints.sm})`,
    tablet: `(min-width: ${breakpoints.tablet})`,
    laptop: `(min-width: ${breakpoints.laptop})`,
    desktop: `(min-width: ${breakpoints.desktop})`,
    "2xl": `(min-width: ${breakpoints["2xl"]})`,
  }