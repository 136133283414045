import styled from "styled-components";
import { Container } from "../../shared";
import { getColor } from "utils";
import { devices } from '../../../utils/constants/media-queries'



const StyledWorkbookFilterList = styled(Container)`
  flex-direction: column;
  max-height: 100%;
  padding: 24px 32px;
  overflow: hidden;
  width: 100%;
  align-items: stretch;
  gap: 8px;
  .list-container {
    display: inline-block;
    max-height: 50vh;
    overflow-y: auto;
    justify-content: flex-start;
    width: 100%;
    align-items: stretch;
    flex-direction: column;
    .filter-container {
      border-bottom: 1px solid ${getColor('border')};
      padding: 12px;
      justify-content: space-between;
      button {
        width: 40px;
        height: 40px;
        padding: 0;
        justify-content: center;
        p{
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
          white-space: nowrap;
        }
      }
      >:nth-of-type(1) {
        button {
          width: unset;
          background: transparent;
        }
      }
    }
  }
  .search-input-container {
    height: 40px;
    input {
      border-radius: 12px;
      height: 40px;
      outline: 0;
    }
  }

  @media only screen and ${devices.sm}{
    .list-container{
      .filter-container{
        button{
          p{
            max-width: 380px;
          }
        }
      }
    }
  }

  @media only screen and ${devices.tablet}{
    .list-container{
      .filter-container{
        button{
          p{
            max-width: 480px;
          }
        }
      }
    }
  }
`

export default StyledWorkbookFilterList