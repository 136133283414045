import styled from "styled-components";
import { Container } from "components"

interface DateRangeContentProps{
    direction: string,
}

const StyledDateRangeContent = styled(Container)<DateRangeContentProps>`
    background-color: white;
    width: 100%;
    border: 1px solid var(--colors-border);
    min-height: 50px;
    align-items: baseline;
    &.expanded-container{
        display: flex;
        position: absolute;
        width: auto;
        top: 145px;
        padding: 0 10px;
        flex-direction: ${({direction}: {direction:string})=> direction};
        .rdrDateRangePickerWrapper{
            .rdrDefinedRangesWrapper{
                display: none;
            }
        }
    }
    &.colapsed-container{
        display: none;
    }
    .range-input{
        position: relative;
        left: ${(props)=>props.direction === 'row' ? '0px' : '10px'};
        top: ${(props)=>props.direction === 'row' ? '0px' : '10px'};
        width: ${(props)=>props.direction === 'row' ? '100px' : '150px'};
        .selected-option-container{
            width: ${(props)=>props.direction === 'row' ? '100px' : '150px'};
        }
    }
    
`

export default StyledDateRangeContent