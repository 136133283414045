import styled from 'styled-components'
import { getColor } from 'utils'
import { devices } from 'utils/constants/media-queries'

const SidebarStyled = styled.aside`
    background: white;
    height: auto;
    position: absolute;
    top: 35px;
    z-index: 11;
    width: 65vw;
    .sidebar-workbooks-links-container {
        margin: 20px 0;
        gap: 16px;
        height: 100%;
        flex-direction: column;
        align-items: start;
            .header-link-container {
                height: 100%;
                cursor: pointer;
                .trigger-container {
                    justify-content: flex-end;
                    gap: 8px;
                    height: 100%;
                    flex: 1;
                    flex-direction: row-reverse;
                    .arrow {
                        border-color: ${getColor('white')};
                    }
                    p {
                        color: ${getColor('brandDark')};
                        font-size: 1.2rem;
                    }
                }
                &.selected {
                    background: ${getColor('clearOverlay')};
                }
                .dropdown-container{
                    flex-direction: column;
                    .dropdown-body-container{
                        &.expanded{
                            display: block;
                            position: initial;
                            margin-left: 25px;
                            transition: ease-in-out all .3s;
                        }
                        &.collapsed{
                            display: none;
                        }
                    }
                }
            }
    }
    .sidebar-external-links{
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 5px;
        .divider{
            position: relative;
            width: 100%;
            height: 1px;
            background: ${getColor('brandClear')};
            margin: 5px 0;
        }
    }

    @media only screen and ${devices.tablet}{
        display: none;
    }

`

export default SidebarStyled