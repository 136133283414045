import styled from "styled-components";
import { Container } from "../../containers";

const StyledArrow = styled(Container)`
  transition: transform 250ms ease-in-out;
  &.down-container {
    transform: rotate(0deg);
  }
  &.up-container {
    transform: rotate(-180deg);
  }
  &.left-container{
    transform: rotate(-90deg);
  }
`
export default StyledArrow;
