interface IEventsCategory{
    navigate: string,
    filter_action: string,
    filter_selection: string,
    export_report: string,
    export_scheduled_report: string
}

interface INavigationEvents{
    view_workbook: string,
    options_menu: string,
}

interface IFilterActions{
    filter_save: string,
    filter_update: string,
    filter_delete: string
}

interface IFilterSelection{
    pistil_filter_select: string,
    sigma_filter_select: string,
}

interface IExportAction{
    download: string,
    send_now: string,
    premium_download: string
}

interface IScheduledExportActions {
    created: string,
    edited: string,
    deleted: string,
    paused: string,
    started: string
}

export const eventsCategory: IEventsCategory = {
    navigate: 'navigate',
    filter_action: 'filter_action',
    filter_selection: 'filter_selection',
    export_report: 'export_report',
    export_scheduled_report: 'export_scheduled_report'
}



export const navigationEvents: INavigationEvents = {
    view_workbook: 'view_workbook',
    options_menu: 'options_menu',
}

export const options_menu = {
    contact_us : 'contact_us',
    help_center: 'help_center',
}

export const filterActions: IFilterActions = {
    filter_save: 'filter_save',
    filter_update: 'filter_update',
    filter_delete: 'filter_delete',
}


export const filterSelection: IFilterSelection = {
    pistil_filter_select: 'pistil_filter_select',
    sigma_filter_select: 'sigma_filter_select'
}

export const exportActions: IExportAction = {
    download: 'download',
    send_now: 'send_now',
    premium_download: 'premium_download'
}

export const scheduledExportActions: IScheduledExportActions = {
    created: 'created',
    edited: 'edited',
    deleted: 'deleted',
    paused: 'paused',
    started: 'started'
}