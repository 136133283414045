import styled from "styled-components"
import { Container } from 'components'
import { getColor } from "../../../../utils"

const StyledPremiumDownload = styled(Container)`
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
    .download-filters-container{
        width: 100%;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px;
        :nth-child(1){
            .range-input{
                .selected-option-container{
                    width: 300px;
                }
            }
        }
        :nth-child(2){
            padding-top: 0;
            .range-input{
                .selected-option-container{
                    width: 100px;
                }
            }
        }
        .download-filter-container{
            :nth-child(2){
                .date-range-content-container{
                    z-index: 2;
                }
            }
            .range-input{
                p.h4{
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 450px;
                }
            }
        }
        .download-filter-container, .dropdown-container{
            width: auto;
            gap: 4px;
            flex-direction: column;
            -webkit-box-align: stretch;
            align-items: stretch;
            margin-right: 10px;

            p.h4{
              font-size: 14px;
              font-weight: 600;
            }
            .dropdown-selector-container{
                border-radius: 20px;
            }
            .dropdown-body-container{
                left: 0px;
            }
        }
    }
    .download-actions-container{
        .download-button{
            background-color: ${getColor('brandDark')};
            color: white;
        }
    }
`

export default StyledPremiumDownload