import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { apiUtils, store } from 'state';
import { dataTrackingOperations } from '..';
import { appNames } from '../../../utils/constants';

const getUser = createAsyncThunk(
  'auth/getUser',
  async ({token, dataTrackingSessionId}: any, { rejectWithValue, dispatch }) => {
    const {trackEvent} = dataTrackingOperations;
    const state = store.getState()
    const { appType }= state.main
    if (state.main.appType) {
      try {
          await apiUtils.checkToken()
          const apiUrl = `${process.env.REACT_APP_API_URL_V2}/UserSettings`
          const { data } = await axios.get(
            apiUrl,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `${token.token_type} ${token.access_token}`,   // TODO probocar error aca paraproblar la pantalal de error
                "App-Origin": `${appType}`
              }
            }
          );
          const user = data
          return ({ dataTrackingSessionId, token, user, message: { message: `${user.emailAddress} logged in as ${appType}`, type: 'success' }})
      } catch (err) {
        dispatch(trackEvent({
          eventCategory: 'error',
          values: JSON.stringify({err}),
          event: `getUser_error`
        }))
        await dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ appSelection:'PAM' }), event: 'switch_on_error' }))
        window.location.assign(`${process.env.REACT_APP_LANDING_URL}/error?redirectUrl=${window.location.href}`)
        if (!(err as Record<string, string>).response) {
          throw err
        }
        return rejectWithValue({message: err.message, type: 'error'})
      }
    }
  }
)

const updateUserWorkbookFilterList = createAction(
  'main/updateUserWorkbookFilterList',
  (user:string) => ({ payload: { user }})
)

const authActions = {
  getUser,
  updateUserWorkbookFilterList
}

export default authActions
