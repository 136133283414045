
import { Container } from './components'
import { devices } from './utils/constants/media-queries';
import { getColor } from 'utils';
import styled from 'styled-components'

const StyledApp = styled(Container)`
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  .environment-indicator-container {
    position: fixed;
    z-index: 10;
    right: 32px;
    bottom: 50px;
    flex-direction: column;
    align-items: flex-end;
  }
  .sidebar-section-container{
    height: 100%;
    width: 80%;
    background-color: white;
    border-right: 1px solid ${getColor('brand')};
    justify-content: start;
    padding: 32px;
    height: 100vh;
    position: absolute;
    left: 0;
    z-index: 10;
    transition: transform 650ms ease-in-out;
    &.expanded-container{
    }
    &.colapsed-container{
      transform: translateX(-100%);
    } 
    .arrow-container{
      position: absolute;
      right: -20px;
      top: 20px;
      padding: 10px;
      border: 1px solid ${getColor('brand')};
      border-radius: 100%;
      background-color: white;
    }
  }
  .sidebar-overlay-container{
    display: block;
    position: absolute;
    top: 72px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    width: 100%;
    height: 100%;
    &.disabled-container{
      background-color: rgba(0, 0, 0, 0);
      display: none;
    }
  }
  @media only screen and ${devices.tablet}{
    .sidebar-section-container{
      display: none;
    }
    .sidebar-overlay-container{
      display: none;
    }
    .workbooks-section-container{
      display: flex;
    }
  }
`

export default StyledApp