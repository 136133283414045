import { Container } from 'components'
import Checkbox from '../../../ui-elements/checkbox'
import { ChangeEvent } from 'react' 

interface IOption{
    option: Record<string, any>,
    onChangeOption: (e:ChangeEvent<HTMLInputElement>) => void,
    isChecked: boolean
    parentId: string
    isRoundedCheckbox?: boolean
}

const Option = ({option, onChangeOption, isChecked, parentId, isRoundedCheckbox = false}: IOption) => {
    return(<Container key={option.id} className={`role-container ${isChecked ? ' checked': 'not-checked'}`}>
        <Checkbox type='checkbox' checked={isChecked} onChange={(e)=>{onChangeOption(e)}} label={option.label} parentId={parentId} id={option.id} isRoundedCheckbox={isRoundedCheckbox}></Checkbox>
    </Container>)
}

export default Option