import React from 'react'
import styled from 'styled-components'
import { Container, Text } from '..'
import { ColorType, getColor } from '../../../utils'

interface TextInputProps {
  placeholder?: string,
  value?: string,
  label?: string,
  textOnly?: boolean,
  multiline?: number,
  onChange?: ((e:React.ChangeEvent<HTMLInputElement>) => void),
  onClick?: React.MouseEventHandler<HTMLInputElement>
  onFocus?: (e:React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e:React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
  type?: string,
  min?: string,
  max?: string,
  color?: ColorType,
  background?: ColorType
}

const StyledInput = styled(Container)`
  position: relative;
  input, textarea {
    padding: 15px 15px;
    width: 100%;
    border-radius: 12px;
    outline: none;
    background: ${({background}:TextInputProps) => background ? getColor(background) : getColor('white')};
    border: 1px solid ${getColor('border')};
    font-family: 'GilroySemiBold';
    font-size: 16px;
    color: ${({color}:TextInputProps)=> color ? getColor(color) : getColor('text')};
    &::placeholder { 
      color: getColor('gray1'); 
      font-family: 'GilroyMedium';
    }
  }
  .label-container {
    position: absolute;
    background: ${getColor('white')};
    top: -12px;;
    padding: 0 4px;
    left: 12px;
    p {
      font-weight: 400;
      font-size: 12px;
      font-family: 'GilroySemiBold';
    }
  }
`

export const TextInput = ({
  placeholder,
  value,
  type='text',
  label,
  onChange,
  onClick,
  onFocus,
  onBlur,
  onKeyDown,
  multiline,
  color,
  min,
  background = 'brand',
  max
}: TextInputProps) => {


  const change = (params:any) => {
    if (onChange) {
      onChange(params.target.value)
    }
  };

  const click = (e:any) => {
    if (onClick) {
      onClick(e)
    }
  }

  const focus = (e:any) => {
    if(onFocus) {
      onFocus(e)
    }
  }

  const blur = (e:any) => {
    if(onBlur) {
      onBlur(e)
    }
  }

  const keyDown = (e:any) => {
    if (onKeyDown) {
      onKeyDown(e)
    }
  }
  return (
    <StyledInput className={'text-input'} color={color}>
      {label && <Container className={'label'}>
        <Text>
          {label}
        </Text>
      </Container>}
      {
        multiline
        ? ( 
          <textarea
            placeholder={placeholder}
            rows={multiline}
            value={value}
            onChange={change}
            onClick={click}
            onFocus={focus}
            onBlur={blur}
            onKeyDown={keyDown}
          >{value}</textarea>
        ) 
        : (
          <input
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            min={min}
            disabled={!onChange}
            max={max}
            maxLength={Number(max)}
            />
        )
      }
    </StyledInput>
  )
}
export default TextInput