import * as React from "react"
import { SVGProps } from "react"
import { getColor } from "../../utils"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.6}
      d="M7.875 12.14V2.39a1.125 1.125 0 0 1 2.25 0v9.75a1.125 1.125 0 1 1-2.25 0Z"
      fill={getColor('brandDark')()}
    />
    <path
      d="M4.125 8.39a1.125 1.125 0 0 1 1.92-.795L9 10.55l2.955-2.955a1.133 1.133 0 0 1 1.836.364 1.118 1.118 0 0 1-.246 1.226l-3.75 3.75a1.118 1.118 0 0 1-1.59 0l-3.75-3.75a1.117 1.117 0 0 1-.33-.795ZM3.75 15.5h10.5a1.125 1.125 0 1 1 0 2.25H3.75a1.125 1.125 0 1 1 0-2.25Z"
      fill={getColor('brandDark')()}
    />
  </svg>
)

export default SvgComponent
