import { createReducer, SerializedError } from '@reduxjs/toolkit'
import actions from './actions'
import { IFilter, IFilterLabel, IWorkbookElement, ISavedFilter, IWorkbookPreview, IExportPremium, IHealthStatus} from 'utils/constants/interfaces'
export type mainState = {
  isFetching: boolean
  error?: SerializedError
  message?: any
  appType: string
  selectedState?: string
  selectedCategories?: string
  selectedWorkbook?: any
  selectedWorkbookV2?: any
  selectedWorkbookGroup?: string
  lastActivityTime?:any
  reverseHeaderIndex:boolean
  selectedSuscriptionIndex:number
  userReports?: any
  isMenuOpen?: boolean
  bottomTabSelected?: string
  isSidebarOpen?: boolean
  selectedModalContent: string
  mainContent?: string
  isModalOpen: boolean
  workbookFilters?: IFilter[] | undefined
  workbookFiltersLabels?: IFilterLabel[] | undefined
  isFetchingWorkbooksFilters: boolean
  isFetchingExportPremiumList: boolean
  savedWorkbookFilters: ISavedFilter[] | undefined
  workbookElements?: IWorkbookElement[] | undefined
  defaultFilterSaved?: ISavedFilter | undefined
  workbookPreview: IWorkbookPreview | undefined
  selectedWorkbookGuid: string | undefined
  exportPremiumList: IExportPremium[] | undefined
  exportPremiumFilters: IFilter[] | undefined
  isFetchingExportPremiumlist: boolean
  healthStatus: IHealthStatus | undefined
  isWorkbookActionsOpen: boolean
}

const initialState: mainState = {
  isFetchingWorkbooksFilters: false,
  isFetchingExportPremiumList: false,
  isFetching: false,
  error: undefined,
  appType: '',
  lastActivityTime: `${new Date()}`,
  reverseHeaderIndex: false,
  selectedSuscriptionIndex: 0,
  workbookFilters: [] as IFilter[] | undefined,
  workbookFiltersLabels: [] as IFilterLabel[] | undefined,
  savedWorkbookFilters: [] as ISavedFilter[] | undefined,
  workbookElements: [] as IWorkbookElement[] | undefined,
  defaultFilterSaved: undefined,
  workbookPreview: undefined,
  selectedWorkbookGuid: undefined,
  selectedModalContent: 'filters',
  isModalOpen: false,
  isSidebarOpen: false,
  exportPremiumList: [] as IExportPremium[] | undefined,
  exportPremiumFilters: [] as IFilter[] | undefined,
  isFetchingExportPremiumlist: false,
  healthStatus: undefined,
  isWorkbookActionsOpen: true
}
const mainReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.setSelectedSuscriptionIndex, (state, action) => {
      state.selectedSuscriptionIndex = action.payload.selectedSuscriptionIndex
    })
    .addCase(actions.setSelectedWorkbookGuid, (state,action)=>{
      state.selectedWorkbookGuid = action.payload.workbookGuid
    })
    .addCase(actions.setAppType, (state, action) => {
      state.appType = action.payload.appType
    })
    .addCase(actions.selectState, (state, action) => {
      state.selectedState = action.payload.selectedState
      state.lastActivityTime = `${new Date()}`
    })

    .addCase(actions.selectCategories, (state, action) => {
      state.selectedCategories = action.payload.selectedCategories
      state.lastActivityTime = `${new Date()}`
    })
    .addCase(actions.getWorkbookElements.fulfilled, (state, action) => {
      state.workbookElements = action.payload.workbookElements
    })
    .addCase(actions.getWorkBookUrlV2.pending, (state) => {
      state.isFetching = true
      state.error = undefined
    })
    .addCase(actions.getWorkBookUrlV2.fulfilled, (state, action) => {
      state.selectedWorkbookV2 = {...state.selectedWorkbookV2,...action.payload.selectedWorkbookV2,defaultedTo: state?.savedWorkbookFilters!.find((swf:ISavedFilter)=>swf.isDefault === true)}
      state.isFetching = false
      state.error = undefined
    })
    .addCase(actions.getWorkBookUrlV2.rejected, (state, action) => {
      state.isFetching = false
    })
    .addCase(actions.getWorkBookUrl.pending, (state) => {
      state.isFetching = true
      state.error = undefined
    })
    .addCase(actions.getWorkBookUrl.fulfilled, (state, action) => {
      state.selectedWorkbook = action.payload.selectedWorkbook
      state.selectedState = action.payload.selectedWorkbook.state
      state.selectedCategories = action.payload.selectedWorkbook.categories
      state.isFetching = false
      state.error = undefined
    })
    .addCase(actions.getWorkBookUrl.rejected, (state, action) => {
      state.isFetching = false
    })
    .addCase(actions.selectWorkbookGroup, (state, action) => {
      state.selectedWorkbookGroup = action.payload.selectedWorkbookGroup
    })
    .addCase(actions.getWorkbookPreview.fulfilled,(state, action)=>{
      state.workbookPreview = action.payload

    })
    .addCase(actions.getWorkbookPreview.pending,(state, action)=>{
      if(state.selectedWorkbookV2?.workbookDashboards.length > 0){
        state.selectedWorkbookV2.workbookDashboards[0].embedUrl = undefined
      }
    })
    .addCase(actions.getWorkbookPreview.rejected,(state, action)=>{
      state.error = action.error
    })
    .addCase(actions.updateControlFilters.fulfilled, (state, action) => {
      state.message = action.payload?.message
      state.selectedWorkbook = { ...state.selectedWorkbook, hasChanges:action.payload?.hasChanges, variables: action.payload?.variables }
      if (action.payload?.isSelected) {
        state.selectedWorkbook = { ...state.selectedWorkbook, controlFilter:action.payload?.controlFilter }
      }
      if (action.payload?.isDefault) {
        state.selectedWorkbook = { ...state.selectedWorkbook, defaultedTo:action.payload?.controlFilter }
      }
    })
    .addCase(actions.resetActivityTimeout, (state) => {
      state.lastActivityTime = `${new Date()}`
    })
    .addCase(actions.deleteControlFilterV2.pending, (state, action) => {
      state.error = undefined
    })
    .addCase(actions.deleteControlFilterV2.fulfilled, (state, action) => {
      state.message = action.payload?.message
      state.lastActivityTime = `${new Date()}`      
      if (action.payload?.isSelected) {
        state.selectedWorkbookV2 = { ...state.selectedWorkbookV2, defaultedTo:null, hasChanges: false }
      }
    })
    .addCase(actions.deleteControlFilterV2.rejected, (state, action) => {
      state.error = action.error
      state.lastActivityTime = `${new Date()}`
    })
    .addCase(actions.deleteControlFilter.fulfilled, (state, action) => {
      state.message = action.payload?.message
      state.lastActivityTime = `${new Date()}`
      if (action.payload?.isSelected) {
        state.selectedWorkbook = { ...state.selectedWorkbook, controlFilter:null, hasChanges: false }
      }
    })
    .addCase(actions.deleteControlFilter.rejected, (state, action) => {
      state.error = action.error
      state.lastActivityTime = `${new Date()}`
    })
    .addCase(actions.downloadPremiumExport.pending, (state, action) => {
      state.message = {message: 'Requesting download, please hold on', type: 'activityIndicator', key: action.meta.requestId}
    })
    .addCase(actions.downloadPremiumExport.rejected, (state, action) => {
      if (action.payload && typeof action.payload === 'object') {
        state.message = { ...action.payload, action: {actionType: 'stopActivityIndicator', targetKey: action.meta.requestId } }
      }
    })
    .addCase(actions.downloadPremiumExport.fulfilled, (state, action)=>{
      state.message = {...action.payload?.message, action: { actionType: 'stopActivityIndicator', targetKey: action.meta.requestId}}
      state.error = undefined
      if (action.payload && typeof action.payload === 'object') {
        state.message = { ...action.payload.message, action: {actionType: 'stopActivityIndicator', targetKey: action.meta.requestId } }
      }
    })
    .addCase(actions.downloadWorkbook.pending, (state, action) => {
      state.message = {message: 'Requesting download, please hold on', type: 'activityIndicator', key: action.meta.requestId}
    })
    .addCase(actions.downloadWorkbook.rejected, (state, action) => {
      if (action.payload && typeof action.payload === 'object') {
        state.message = { ...action.payload, action: {actionType: 'stopActivityIndicator', targetKey: action.meta.requestId } }
      }
    })
    .addCase(actions.downloadWorkbook.fulfilled, (state, action) => {
      state.message = {...action.payload.message, action: { actionType: 'stopActivityIndicator', targetKey: action.meta.requestId}}
      state.error = undefined
      if (action.payload && typeof action.payload === 'object') {
        state.message = { ...action.payload.message, action: {actionType: 'stopActivityIndicator', targetKey: action.meta.requestId } }
      }
    })
    .addCase(actions.getUserReports.fulfilled, (state, action) => {
      state.userReports = action.payload.userReports
    })
    .addCase(actions.getWorkbookFiltersLabels.fulfilled, (state, action)=>{
      state.workbookFiltersLabels = action.payload?.labels
    })
    .addCase(actions.getSavedWorkbookFilters.pending,(state, action)=>{
      state.error = undefined
    })
    .addCase(actions.getSavedWorkbookFilters.fulfilled,(state, action)=>{
      const { savedWorkbookFilters } = action.payload
      state.savedWorkbookFilters = savedWorkbookFilters
      state.defaultFilterSaved = savedWorkbookFilters && savedWorkbookFilters.find(({isDefault}:ISavedFilter)=>isDefault)
    })
    .addCase(actions.scheduleSendReportV2.rejected,(state, action)=>{
      state.message = {...action.payload!}
    })
    .addCase(actions.updateSavedWorkbookFiltersList.fulfilled,(state,action)=>{
      state.savedWorkbookFilters = action.payload.savedWorkbookFilters!
    })
    .addCase(actions.updateSelectedFilterSaved.fulfilled,(state, action)=>{
      state.selectedWorkbookV2 = {...state.selectedWorkbookV2, selectedSavedFilter: action.payload.selectedSavedFilter}
    })
    .addCase(actions.updateDefaultFilterSaved.fulfilled,(state, action)=>{
      state.defaultFilterSaved = action.payload.defaultFilterSaved
    })
    .addCase(actions.saveWorkbookFilters.fulfilled,(state, action)=>{
      state.message = action.payload?.message
      if (action.payload?.filterGroup.isDefault) {
        state.selectedWorkbookV2 = { ...state.selectedWorkbookV2, defaultedTo:action.payload?.filterGroup }
        state.defaultFilterSaved = action.payload.filterGroup
      }
      if(action.payload.sigmaControls.length === 1 ){
        let sigmaControl = action.payload.sigmaControls[0];
        let filteredSigmaControls = state.selectedWorkbookV2?.sigmaControls?.filter((sc:any)=>sc.sigmaControlId !== sigmaControl.sigmaControlId)
        if(filteredSigmaControls){
          state.selectedWorkbookV2 = {...state.selectedWorkbookV2, sigmaControls: [...filteredSigmaControls, sigmaControl] }
        }else{
          state.selectedWorkbookV2 = {...state.selectedWorkbookV2, sigmaControls: [sigmaControl]}
        }
      }else{
        state.selectedWorkbookV2 = {...state.selectedWorkbookV2, sigmaControls: action.payload?.sigmaControls}
      }  
      if(action.payload?.isSelected){
        state.selectedWorkbookV2 = { ...state.selectedWorkbookV2,  savedFilter: action.payload?.filterGroup}
      }

      if(action.payload?.isDefault){
        state.selectedWorkbookV2 = { ...state.selectedWorkbookV2,  defaultedTo: action.payload?.filterGroup}
      }
    })
    .addCase(actions.getWorkBookPremiumFilters.pending, (state, action) => {
      state.isFetchingExportPremiumList = true
      state.error =undefined
    })
    .addCase(actions.getWorkBookPremiumFilters.rejected, (state, action) => {
      state.isFetchingExportPremiumList = false
    })
    .addCase(actions.getWorkBookPremiumFilters.fulfilled, (state, action) => {
      state.isFetchingExportPremiumList = false
      state.exportPremiumFilters = action.payload.exportPremiumFilters
    })
    .addCase(actions.getExportPremiumList.pending, (state, action) => {
      state.isFetchingExportPremiumList = true
      state.error = undefined
    })
    .addCase(actions.getExportPremiumList.rejected, (state, action) => {
      state.isFetchingExportPremiumList = false
    })
    .addCase(actions.getExportPremiumList.fulfilled, (state, action) => {
      state.isFetchingExportPremiumList = false
      state.exportPremiumList = action.payload.exportPremiumList
    })
    .addCase(actions.getWorkbookFilters.pending, (state, action) => {
      state.isFetchingWorkbooksFilters = true
      state.error = undefined
    })
    .addCase(actions.getWorkbookFilters.fulfilled, (state, action) => {
      state.workbookFilters = action.payload.filters
      state.isFetchingWorkbooksFilters = false
    })
    .addCase(actions.getWorkbookFilters.rejected, (state, action) => {
      state.isFetchingWorkbooksFilters = false
    })
    .addCase(actions.sendReport.fulfilled, (state, action ) => {
      state.message = action.payload?.message
    })
    .addCase(actions.setMenuOpen, (state, action)=>{
      state.isMenuOpen = action.payload?.isMenuOpen
    })
    .addCase(actions.setBottomTabNavigation, (state, action)=>{
      state.bottomTabSelected = action.payload?.bottomTabSelected
    })
    .addCase(actions.setSidebarOpen, (state, action)=>{
      state.isSidebarOpen = action.payload?.isSidebarOpen
    })
    .addCase(actions.setSelectedModalContent, (state, action )=>{
      state.selectedModalContent = action.payload?.selectedModalContent
    })
    .addCase(actions.setMainContent, (state, action)=>{
      state.mainContent = action.payload?.mainContent
    })
    .addCase(actions.setModalOpen,  (state, action)=>{
      state.isModalOpen = action.payload.isModalOpen
    })
    .addCase(actions.showWorkbookActions, (state, action) =>{
      state.isWorkbookActionsOpen = action.payload.isOpen
    })
    .addCase(actions.getHealthStatus.fulfilled, (state, action)=>{
      const {  userHealthStatus: status, userHealthStatusString: description, userHealthIssues: issues} = action.payload.data
      state.healthStatus = { status, description, issues }
    })
})

export default mainReducer
