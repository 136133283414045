import * as React from "react"
import { SVGProps } from "react"
import { getColor } from "utils"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
const brandDarkColor:string = getColor('brandDark')()
const brandColor:string = getColor('brand')()

return (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 2.75H2.25a.75.75 0 0 0-.75.75V5c0 .414.336.75.75.75h8.25a.75.75 0 0 0 .75-.75V3.5a.75.75 0 0 0-.75-.75Z"
      fill={brandDarkColor}
    />
    <path
      opacity={0.6}
      d="M15.75 2.75h-1.5a.75.75 0 0 0-.75.75V5c0 .414.336.75.75.75h1.5A.75.75 0 0 0 16.5 5V3.5a.75.75 0 0 0-.75-.75ZM10.5 8H2.25a.75.75 0 0 0-.75.75v1.5c0 .414.336.75.75.75h8.25a.75.75 0 0 0 .75-.75v-1.5A.75.75 0 0 0 10.5 8Z"
      fill={brandDarkColor}
    />
    <path
      d="M15.75 8h-1.5a.75.75 0 0 0-.75.75v1.5c0 .414.336.75.75.75h1.5a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75ZM10.5 13.25H2.25a.75.75 0 0 0-.75.75v1.5c0 .414.336.75.75.75h8.25a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75Z"
      fill={brandColor}

    />
    <path
      opacity={0.6}
      d="M15.75 13.25h-1.5a.75.75 0 0 0-.75.75v1.5c0 .414.336.75.75.75h1.5a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75Z"
      fill={brandColor}

    />
  </svg>
)}

export default SvgComponent
