import { getColor } from '../../../utils'
import { Container } from '..'
import styled from 'styled-components'


const  StyledDropdownInputRefactorized = styled(Container)`
  &.disabled{
    pointer-events: none;
    color: ${getColor('inactive')};
    user-select: none;
    .label-container{
      p{
        color: ${getColor('inactive')} !important;
      }
    }
  }
  &.default {
    border: 1px solid ${getColor('sigmaBorder')};
    &.active {
      border: 1px solid ${getColor('brand')}!important;
    }
    position: relative;
    cursor:pointer;
    transition: unset;
    .dropdown-body-container  {
      top: calc(100% + 1px);
      border: 1px solid ${getColor('sigmaBorder')};
      overflow: hidden;
      background: #FBFAFF;
      box-shadow: rgb(22 22 22 / 20%) 0px 4px 8px -2px;
      width: 300px;
      display: flex;
      flex-direction: column;
    }
    .options-container {
      z-index: 2;
      max-height: calc(50vh - 72px);
      overflow: auto;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      text-overflow: ellipsis;
      .role-container {
        padding: 0px 8px;
        border: 1px solid #FBFAFF;
        justify-content: flex-start;
        &.checked-container{
          &:hover{
            border: 1px solid ${getColor('brand')};
          }
        }
        p {
          font-size: 12px;
          font-weight: 400;
        }
        &:hover {
          border: 1px solid ${getColor('sigmaBorder')};
        }
        label {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          .checkbox-container {
            border-radius: 2px;
            &:hover{
              cursor: pointer;
              border: 1px solid ${getColor('brand')};
            }
            .check-container {
              display: none;
              color: ${getColor('brand')};
            }
          }
          input {
            display: none;
            &:checked + .checkbox-container {
              .check-container {
                display: flex;
              }
            }
          }
          .checkbox-container {
            border: 1px solid ${getColor('sigmaBorder')};
            width: 14px;
            height: 14px;
          }
        }
      }
      button.action-button {
        width: 75px;
        align-self: flex-end;
        margin-bottom: 8px;
        margin-right: 8px;
      }
      .message-container {
        max-width: 150px;
        align-self: center;
        text-align: center;
      }
    }
    .dropdown-body-container {
      z-index: 1;
      transition: unset;
      overflow: visible;
    }
    .trigger-container {
      width: 230px;
      padding: 4px 8px;
      height: 30px;
      .label-container {
        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }
    }
  }
  .selected-option-label-container{
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 28px;
    border-top: 1px solid ${getColor('sigmaBorder')};
    padding: 4px 8px;
    background-color: #efedf5;
    p{
      width: 100%;
      text-align: start;
      font-size: 12px;
      margin: 0;
      color: #736a8c;
    }
  }
`

export default StyledDropdownInputRefactorized