import { Button, Container, Text } from "components"
import StyledRangeOptions from "./range-options-styled"
import { useRef, useState } from "react"
import { AiFillCaretDown } from "react-icons/ai"
import { useClickOutside } from 'utils';

const RangeOptions = ({isContentExpanded, selectedOption, setSelectedOption, options}:{isContentExpanded:boolean, selectedOption:string, setSelectedOption: React.Dispatch<React.SetStateAction<string | 'Between'>>, options: Record<string,string>[]}) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const dropdownRef = useRef(null);
    
    const handleOnClick = (option:string) => {
        setIsExpanded((prev)=>!prev)
        setSelectedOption(option)
    }


    const handleOnClose = () => {
        setIsExpanded(false)
    }

    useClickOutside(dropdownRef, handleOnClose)
    return(<>
        <StyledRangeOptions className={`range-input ${isContentExpanded ? 'expanded' : 'colapsed'}`} REF={dropdownRef}>
            <Container className={`${isExpanded ? 'bordered': ''} selected-option`} onClick={()=>setIsExpanded((prev)=>!prev)}>
                <Text textStyle="h4">{selectedOption}</Text>
                <Button className={`x-icon ${'22'.length >= 1 ? 'visible' : 'hidden'}`} onClick={()=>{}}>
                    <Container className={`arrow`} ><AiFillCaretDown size={10}/></Container>
                </Button>
            </Container>
            <Container className={`${isExpanded ? 'expanded' : 'colapsed'} options`}  >
                {
                    options.map((o) =>
                        <Container className={`${selectedOption === o.label ? 'selected': ''} option`} onClick={()=>handleOnClick(o.label)}>
                            <Text textStyle="s2">{o.label}</Text>
                        </Container>
                    )
                }
            </Container>
        </StyledRangeOptions>
    </>)
}

export default RangeOptions