import styled from "styled-components";
import { getColor } from "utils";
import { ButtonProps } from "./interfaces";

const StyledButton = styled.button`
  background-color: ${({color}:ButtonProps) => getColor(color || 'brand')};
  padding: 15px;
  border-radius: 15px;
  transition: ease-in-out .3s all;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &.fetching {
    cursor: wait;
    opacity: 0.75;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: ${({color}:ButtonProps) => getColor(color || 'clearOverlay')};
  }
`

export default StyledButton;