import { ChangeEvent, useEffect, useState } from 'react' 
import { Container, Text} from 'components'
import Option from './Option'
import { IFilterValue, IFilterValueSelected } from 'utils/constants/interfaces'

interface IOptionsProps{
    options: [],
    selectedValues: IFilterValue[]
    parentId: string
    handleOnChangeOption: (e:ChangeEvent<HTMLInputElement>, option: Record<string,any>) => void
}

const Options = ({options, selectedValues, parentId, handleOnChangeOption}: IOptionsProps) => {
    const getSelectedOption = (option: {label:string, id: string})=>{
        return !!selectedValues.find((v: IFilterValue) => v.id === option.id)?.isSelected!
    }
    return(<Container className={'options'}>
        {options.length !== 0 ? options.map((option: {id: string, label: string})=>{
            return (<Option option={option} onChangeOption={(e)=>{handleOnChangeOption(e,option)}} isChecked={getSelectedOption(option)} parentId={parentId}/>)
        }) : <div style={{margin: '8px'}}><Text textStyle='s1'>0 results found</Text></div>}
    </Container>)
}

export default Options