import { authSelectors, dataTrackingOperations, hooks, mainOperations, mainSelectors } from 'state'
import { Button, Container, DropdownMenu, Modal, Text } from 'components'
import { useEffect, useState } from 'react'
import { IoMdClose, IoMdArrowBack } from 'react-icons/io'
import { getColor, usePrevious } from 'utils'
import { WorkbookDownload, WorkbookExport, WorkbookFilterList, WorkbookFilterSave } from '.'
import { v4 as uuid } from 'uuid'
import { DownloadIcon, FilterListIcon, SaveIcon } from 'assets'
import StyledWorkbookActions from './workbook-actions-styled'
import { 
  sendNowTitle,
  myFiltersTitle,
  saveFilterTitle,
  downloadTitle,
  scheduledSendTitle,
  setDefaultEmailBodyContent,
  filterWarningTitle,
  filterWarningMessage,
  filterActionSuggestion,
  okLabel,
  saveFilterLabel,
  yourFiltersLabel,
  sendLabel,
  premiumDownloadTitle
} from 'utils/constants/index'
import { fieldsInitialValues } from 'utils/constants'
import WorkbookSendExport from './workbook-send-export'
import ArrowButton from 'components/shared/ui-elements/arrow-button';
import WorkbookFilters from './workbook-filters'
import { isDownloadAllowed  } from 'utils/tools'
import { IOptionsSend } from './interfaces'
import PremiumDownload from './PremiumDownload'

export const WorkbookActions = () => {
  const { useAppSelector, useAppDispatch } = hooks
  const { selectmain } = mainSelectors
  const { selectAuth } = authSelectors
  const { getWorkBookUrl, setMainContent, setModalOpen, setSelectedModalContent} = mainOperations
  const { selectedState, selectedWorkbook, selectedSuscriptionIndex, userReports, error , mainContent, isModalOpen, workbookFilters, selectedWorkbookV2,workbookElements, selectedModalContent, isWorkbookActionsOpen} = useAppSelector(selectmain)
  const { user } = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()
  const prevState = usePrevious(selectedState)
  const workbookReports = userReports?.filter(({ workbookGuid: reportWorkbookGuid }: any) => reportWorkbookGuid === selectedWorkbookV2?.workbookGuid)
  let attachments: {label:string, value: string}[] | undefined= []

  attachments = workbookElements?.map(({elementDisplayName, workbookElementGuid} : any)=>({
    label: elementDisplayName,
    value: workbookElementGuid
  }))
  const fieldsInitialValuesExtended = {
    ...fieldsInitialValues,
    body: setDefaultEmailBodyContent(selectedWorkbookV2?.workbookDisplayName),
    dashBoardItems: (()=>{
      return ([(selectedWorkbookV2?.workbookName || selectedWorkbook?.workbookTitle) === 'Stock Report' ? attachments?.find((attachment:any)=>attachment.label === 'Stock detail') : attachments?.[0]])
    })()
  }
  const [ initialValues, setInitialValues ] = useState(fieldsInitialValuesExtended)
  // const [isSideMenuOpen, setIsModalOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState()
  const [ isEditFilterClicked , setIsEditFilterClicked] = useState(false)
  const [isSendScheduled,setIsSendScheduled] = useState(false)

  let userSettings = (((user as any)?.subscriptions[selectedSuscriptionIndex].settings))
  let canDownload = isDownloadAllowed(workbookFilters!,userSettings)!

  const [showReportList, setShowReportList] = useState<any>(undefined)


  const selecteSideMenuTabTitles: any = {
    filters: myFiltersTitle,
    selection: saveFilterTitle,
    download: downloadTitle,
    premiumDownload: premiumDownloadTitle,
    send: sendLabel,
    schedule: scheduledSendTitle,
    sendNow: sendNowTitle,
    error: filterWarningTitle
  }

  useEffect(() => {
    if (prevState && selectedState) {
      dispatch(getWorkBookUrl({
        workbookGuid: selectedWorkbook.workbookGuid,
        state: selectedState
      }));
    }
  }, [selectedState])

  useEffect(() => {
  }, [selectedFilter])
  useEffect(() => {
    if (error?.message === 'Rejected') {
      dispatch(setModalOpen(true));
      dispatch(setSelectedModalContent('error'))
    }
  }, [error])

  useEffect(()=>{
    if(mainContent && isModalOpen){
      mainContent === 'home' && dispatch(setModalOpen(false))
      dispatch(setModalOpen(isModalOpen))
      dispatch(setSelectedModalContent(mainContent))
    }
  },[mainContent, isModalOpen ])

  const handleCloseOnClick = () => {
    if(workbookReports?.length && isSendScheduled){
      if(showReportList){
        dispatch(setModalOpen(false))
      }else{  
        setShowReportList(true)
        dispatch(setModalOpen(true))
      }
    }else{
      setShowReportList(false)
      dispatch(setModalOpen(false))
    }
    setInitialValues(fieldsInitialValuesExtended)
    setSelectedFilter(undefined)
    dispatch(setMainContent('home'))
  }
  


  const setNowOnRequestClose = () => {
    dispatch(setModalOpen(false))
    setInitialValues(fieldsInitialValuesExtended)
  }

  const onSaveFilter = (filterGuidParam: string) => {
    setSelectedFilter(undefined)
    dispatch(setModalOpen(false))
  }

  const onEditFilter = (controlFilterParams: any) => {
    dispatch(setMainContent('selection'))
    dispatch(setSelectedModalContent('selection'))
    setSelectedFilter(controlFilterParams)
    setIsEditFilterClicked(true)
  }

  const handleSendTypeChange = ({action: sendTypeSelected}:IOptionsSend) =>{
    if(sendTypeSelected === 'schedule'){
      if(  workbookReports?.length > 0){
        setShowReportList(true)
      }
      setIsSendScheduled(true)
      dispatch(setModalOpen(true))
      dispatch(setMainContent(sendTypeSelected))
      dispatch(setSelectedModalContent(sendTypeSelected))
    }else{
      setIsSendScheduled(false)
      dispatch(setModalOpen(true))
      dispatch(setMainContent(sendTypeSelected))
      dispatch(setSelectedModalContent(sendTypeSelected))
    }
  }

  const handleGoBack = () => {
    if(mainContent){
      switch (mainContent) {
        case 'selection':
          dispatch(setMainContent('filters'))
          break
        case 'sendNow':
          dispatch(setMainContent('send'))
          break 
        case 'schedule':
          dispatch(setMainContent('send'))
          break
        case 'filters':
          dispatch(setMainContent('home'))
          break
        default:
          dispatch(setModalOpen(false))
          dispatch(setMainContent('home'))
          break
      }
      dispatch(setModalOpen(false))
    }    
  }
  return (
    <StyledWorkbookActions className={'workbook-actions'}>
      <Container className={'action-list'}>
        <Container className={'right-side'} >
          {
              isWorkbookActionsOpen && <WorkbookFilters/> 

          }
        </Container>
        {<Container className={`${workbookFilters && workbookFilters?.length > 0 ? 'enabled' : 'disabled'} left-side-container top`}>
          <Container className={'filter-actions'}>
            <Button className={'action-button'} onClick={() => { 
              dispatch(setModalOpen(true)); 
              dispatch(setSelectedModalContent('filters'))
              dispatch(setMainContent('filters'))
            }}>
              <Text>{yourFiltersLabel}</Text>
              <FilterListIcon />
            </Button>
            <Button
              className={'action-button'}
              onClick={() => {
                dispatch(setModalOpen(true));
                setSelectedFilter(selectedWorkbook?.controlFilter);
                dispatch(setSelectedModalContent('selection'))
                dispatch(setMainContent('selection'))
              }}
              tooltip={selectedWorkbook?.controlFilter?.name && { label: selectedWorkbook?.controlFilter?.name, position: 'bottom' }}
            >
                <Text>
                  {selectedWorkbookV2?.savedFilter?.savedFilterName || saveFilterLabel}
                </Text> 
              <Container className={'changes-indicator'}>
                {(selectedWorkbook?.controlFilter?.name && selectedWorkbook?.hasChanges) && <Text textStyle='s1'>(modified)</Text>}
              </Container>
              <SaveIcon />
            </Button>
          </Container>
          <Container className={'send'} >
            <DropdownMenu
              label={'Send'}
              options={[
                { key: uuid(), label: 'Send Now', action: 'sendNow' },
                { key: uuid(), label: 'Schedule', action: 'schedule' }
              ]}
              onChange={(selectedOption:IOptionsSend)=>handleSendTypeChange(selectedOption)}
              disabled={!canDownload}
              arrorDirection="down-up"
            />
          </Container>

          <Container>
            <Button 
              className={'action-button'}
              disabled={!canDownload} 
              onClick={() => { 
                dispatch(setModalOpen(true)); 
                dispatch(setSelectedModalContent('download'))
                dispatch(setMainContent('download'))
              }}>
              <Text>Download</Text>
              <DownloadIcon />
            </Button>
          </Container>
        </Container>}
      </Container>
      <Modal
        position={`${selectedModalContent === 'premiumDownload' ? 'top':'center'}`}
        isOpen={isModalOpen && selectedModalContent !== 'home'}
        requestClose={() => {
          dispatch(setModalOpen(false))
          setTimeout(() => {
            dispatch(setSelectedModalContent('filters'));
          }, 300);
          setInitialValues(fieldsInitialValuesExtended)
        }}
      >
        <Container className={'workbook-actions-modal'}>
          <Container className={'workbook-actions-modal-title'}>
            <Container className={'separator'} /> 
            <ArrowButton  className='arrow-button' backgroundColor='white' color='brandDark' onClick={handleGoBack}><IoMdArrowBack size={25}/></ArrowButton>
            <Text textStyle='h1'>
              {
                selecteSideMenuTabTitles[selectedModalContent]
              }
            </Text>
            <Button onClick={handleCloseOnClick}>
              <IoMdClose size={'42px'} color={`${getColor('brandDark')}`} />
            </Button>
          </Container>
          {
            selectedModalContent === 'filters'
            && <WorkbookFilterList onEditFilter={onEditFilter} setIsModalOpen={(val:boolean)=>{dispatch(setModalOpen(val))}}/>
          }
          {
            selectedModalContent === 'selection'
            && <WorkbookFilterSave selectedState={selectedState} onSave={onSaveFilter} selectedFilter={selectedFilter} isEditFilterClicked={isEditFilterClicked} setIsEditFilterClicked={setIsEditFilterClicked}/>
          }
          {
            selectedModalContent === 'download'
            && <WorkbookDownload />
          }
          {
            selectedModalContent === 'send'
            && <WorkbookSendExport />
          }
          {
            selectedModalContent === 'schedule'
            && <WorkbookExport
                initialValues={initialValues} 
                selectedState={selectedState} 
                isSendScheduled = {isSendScheduled}
                onRequestClose={handleCloseOnClick} 
                selectedFilter={selectedWorkbookV2.savedFilter} 
                setShowReportList={(v: any) => setShowReportList(v)}  
                showReportList={showReportList} />
          }
          {
            selectedModalContent === 'premiumDownload' 
            && <PremiumDownload/>
          }
          {
            selectedModalContent === 'sendNow'
            && <WorkbookExport 
              initialValues={initialValues} 
              selectedState={selectedState} 
              isSendScheduled = {isSendScheduled}
              onRequestClose={setNowOnRequestClose} 
              selectedFilter={selectedWorkbookV2?.savedFilter} 
              setShowReportList={(v: any) => setShowReportList(v)} 
              showReportList={showReportList} />
          }
          {
            selectedModalContent === 'error'
            &&
            <Container className={'action-section'}>
              <Container className='suggestion'>
                <Text color='suggestion'>{filterWarningMessage}</Text>
                <Text color='suggestion'>{filterActionSuggestion}</Text>
              </Container>
              <Container className={'action-buttons'}>
                <Button onClick={() => dispatch(setModalOpen(false))}><Text>{okLabel}</Text></Button>
              </Container>
            </Container>
          }
        </Container>
      </Modal>
    </StyledWorkbookActions>
  )
}
export default WorkbookActions
