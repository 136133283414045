import { addDays } from 'date-fns';
import React, { InputHTMLAttributes, useState } from 'react'
import { DateRangePicker, StaticRange, DefinedRangeProps, DateRangePickerProps, InputRange, RangeKeyDict, Range, RangeFocus } from 'react-date-range'
import { getColor } from '../../../../utils';

interface IDateRangeProps {
  staticRanges: StaticRange[]
}

const RangeDropdownInput = ({...props}) =>{
  

  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
])
  // const [startDate, setStartDate] = useState(new Date())
  // const [endDate, setEndDate] = useState(new Date())
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection'
  //   },
  // ]as Range[]);
  const handleSelect = (date: RangeKeyDict) => {
    setState([{
      startDate: date.selection.startDate,
      endDate: date.selection.endDate,
      key: 'selection'
    }])
    // setStartDate(date?.selection?.startDate!)
    // setEndDate(date?.selection?.endDate!)
  }

  // const selectionRange ={
  //   startDate: startDate,
  //   endDate: endDate,
  //   key: "selection"
  // }
  return <DateRangePicker
  showPreview
  moveRangeOnFirstSelection={false}
  inputRanges={[] as InputRange[]}
  months={2}
  ranges={state}
  // ranges={state}
  direction="horizontal"
  rangeColors={[getColor('brandDark')()]}
  {...props}
  // onChange={(item:RangeKeyDict) => setState([item.selection])}
  onChange={handleSelect}
  color={getColor('brandDark')()}
/>
}


export default RangeDropdownInput
