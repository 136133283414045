import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { appNames } from 'utils/constants'
import { IWorkbook } from 'utils/constants/interfaces'

const selectmain = (state: RootState) => state.main
const state = (state: RootState) => state

const selectWorkbookListItems = createSelector(state, (root:any) => {
  return root.auth.user?.subscriptions[root.main.selectedSuscriptionIndex].workbooks
})

const selectWorkbookTier = createSelector(state, (root: any) => {
  const workbookGuid = root.main.selectedWorkbookGuid
  return root.auth.user.subscriptions[root.main.selectedSuscriptionIndex].workbooks.find((wb: IWorkbook)=>wb.workbookGuid === workbookGuid)?.groupTier
})

const selectHeaderTabs = createSelector(state, (root:any) => {
  const compareFn = (a:any, b:any) => {
    return a.groupOrder - b.groupOrder
  }

  const userWorkBooks = Array.isArray(root.auth.user?.subscriptions[root.main.selectedSuscriptionIndex].workbooks) ? [...root.auth.user?.subscriptions[root.main.selectedSuscriptionIndex].workbooks] : []
  const workbooks = userWorkBooks.sort(compareFn)

  return workbooks.reduce((acc:any, curr:any) => {
    const groupString = curr.groupString.charAt(0) + curr.groupString.slice(1).toLowerCase();
    if (groupString === 'Undefined' || acc.find((curr:any) => curr.groupString === groupString)) {
      return acc
    }
    return ([ ...acc, {...curr, groupString} ])
  }, [])
})

const selectWorkbookTabs = createSelector(state, (root:any) => {
  const compareFn = (a:any, b:any) => {
    return a.tabGroupOrder - b.tabGroupOrder
  }
  const selectedWorkbookGroup = root.main.selectedWorkbookGroup
  const workbooks = selectedWorkbookGroup 
    && root.auth.user?.subscriptions[root.main.selectedSuscriptionIndex].workbooks.filter(({ groupString }:any) => groupString === selectedWorkbookGroup.toUpperCase() )

  const tabs = workbooks?.sort(compareFn).map((workbook:any) => {
    const { workbookGuid }  = workbook
    let workbookTitle = ''
    workbookTitle = workbook.workbookDisplayName
    const tabLabel = workbookTitle
    return ({ workbookGuid, tabLabel })
  })
  return tabs
})


const mainSelectors = {
  selectmain,
  selectHeaderTabs,
  selectWorkbookTabs,
  selectWorkbookListItems,
  selectWorkbookTier
}

export default mainSelectors
