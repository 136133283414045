import React from 'react'
import styled from 'styled-components'
import { Container } from '../containers'
import { Text } from 'components/shared/ui-elements/text'
import { ColorType, getColor } from '../../../utils'


const StyledSpinner = styled(Container)`
  flex-direction: column;
  margin: 30px auto;
  .spinner-content-container{
    --size: ${({size}:ISpinner)=>size ? size : '24px'};
    height: var(--size);
    width: var(--size);
    margin-bottom: 30px;
    box-sizing: content-box;
    .loading-spinner{
      --spinner-b-color: ${({color}:ISpinner)=>color ? getColor(color) : getColor('brand')};
      border-color: transparent  var(--spinner-b-color) var(--spinner-b-color);
      height: 24px;
      width: 24px;
    }
  }
`

interface ISpinner{
  children: React.ReactNode
  text: string
  size: string
  color?: ColorType
}
export const Spinner  = ({children, text, size = '20px',color = 'brand'}:ISpinner) => {
  return (
    <StyledSpinner className={'spinner-wrapper'} size={size} text='' color={color}>
      <Container className={'spinner-content'}>
        {children}
      </Container>
      <Text>{text}</Text>
    </StyledSpinner>
  )
}
export default Spinner


