import styled from "styled-components";
import { Container} from "components"
import { getColor } from "../../../../utils";
const StyledDateRangePicker = styled(Container)`
    flex-direction: column;
    align-items: flex-start;
    >p.h4{
        margin-bottom: 5px;
    }

    .label-container{
        border: 1px solid rgb(215, 212, 225);
        border-radius: 20px;
        padding: 4px 8px;
        height: 30px;
        line-height: 14px;
        width: 300px;
        &.bordered{
            border: 1px solid ${getColor('brandDark')};
        }
        p{
            color: rgb(115, 106, 140);
            width: 100%;
            font-size: 14px;
            padding-left: 5px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            max-width: 290px;
            white-space: nowrap;
            height: 15px;
        }
        .x-icon{
            line-height: 6px;
            padding: 0;
           :hover{
            cursor: pointer;
            svg{
                background-color: #E0DEE7;
            }
           }
        }
    }
`
export default StyledDateRangePicker