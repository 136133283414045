import styled from "styled-components";
import { Container } from "../../../shared";
import { devices } from "../../../../utils/constants/media-queries";

const WorkbookPreviewStyled = styled(Container)`
    height: 100%;
    width: 100%;
    z-index: 100;
    flex-direction: column;
    position: relative;
    background: #fafbff;
    .workbook-preview-image{
        position: relative;
        height: 100vh;
        background-color: #FAFBFF;
        img.workbook-image{
            width: auto;
            height: auto; 
            position: relative;
            border-top-right-radius: 32px;
            border-top-left-radius: 32px;
        }
    }
    .workbook-loading-container{
        background: white;
        flex: 1;
        width: 100vw;
        height: 100vh;
        justify-content: start;
        position: absolute;
        flex-direction: column;
        top:-35vh;
        padding-top:10vh;
        p {
            font-size: 24px;
            font-weight: normal;
        }
    }
    .benefits{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        bottom: 35vh;
        .workbook-preview-content{
            display: flex;
            width: auto;
            flex-direction: row;
            align-items: center;
            position: absolute;
            justify-content: flex-start;
            margin-top:5vh;
            gap: 100px;
            .tier-icon{
                width: 92px;
            }
            .workbook-preview-body{    
                display:flex;
                flex-direction: column;
                align-items: center;
                p.h2{
                    font-size: 24px;
                    font-weight: 600;
                    color: #6B6A6A;
                    margin-top: 30px;
                }
                .benefits-list-container{
                    flex-direction: column;
                    width: auto;
                    align-items: start;
                    margin: 30px 0;
                    max-width: 50vw;
                    .benefit-item-container{
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        img{
                            margin-right: 18px;
                        }
                        p.h5{
                            color:#8C8C8C;
                            font-size: 20px;
                            font-weight: 400;
                        }
                    }
                }
                .message-footers-container{
                    margin-top: 20px;
                    .h5{
                        color: rgb(107, 106, 106);
                    }
                }
                button.upgrade-button{
                    width: 262px;
                    color: white;
                    font-size: 16px;
                    font-weight: 700;
                    border-radius: 36px;
                    background-color: #1B7FF3;
                }
            }
        }
        .fade-out{
            width: 100vw;
            height: 100vh;
            position: absolute;
            background: linear-gradient(182deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.99) 15%, rgba(255, 255, 255, .98) 35%, rgba(255, 255, 255, .99) 50%, rgba(255,255,255,.99));
        }
    }


    @media only screen and ${devices.tablet}{
        .benefits{
            bottom: 30vh;
        }
    }

    @media only screen and ${devices.laptop}{
        .benefits{
            bottom: 25vh;
        }
    }

    @media only screen and ${devices.desktop}{
        .benefits{
            bottom: 29vh;
        }
    }

    @media only screen and ${devices["2xl"]}{
        .benefits{
            bottom: 35vh;
        }
    }

    @media only screen and (min-width: 2000px){
        .benefits{
            bottom: 45vh;
        }
    }
`

export default WorkbookPreviewStyled