import styled from "styled-components";
import Container from 'components/shared/containers/container'
import { getColor } from 'utils'
import { devices } from "utils/constants/media-queries";
const StyledWorkbookViewer = styled(Container)`
  flex:1;
  height: 100%;
  width: 100%;
  flex-direction:column;
  align-items: stretch;
  background: ${getColor('white')};
  background:rgb(251, 250, 255);
  justify-content: start;
  .title-container {
    background: rgb(251,250,255);
    padding-top: 16px;
    padding-bottom: 20px;
    &.left-side-container{
      display: flex;
    }
  }
  iframe {
    flex: 1;
    height: 100%;
    z-index: 1;
    padding: 0 0 32px 20px;
    background: rgb(251,250,255);
    border: 0;
    padding-bottom: 80px;
  }
  .iframe-icon-container {
    flex: 1;
    width: 100%;
    max-height: 500px;
    position: absolute;
    flex-direction: column;
    p {
      margin-top: 100px;
      font-size: 24px;
      font-weight: normal;
    }
  }
  @media only screen and ${devices.tablet}{
    .title-container {
      background: rgb(251,250,255);
      padding-top: 25px;
      padding-bottom: 25px;
      &.left-side-container{
        display: inline;
        margin-left: 48px;
      }
    }
  }
`

export default StyledWorkbookViewer